.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  overflow: hidden;
  & > a {
    text-decoration: underline;
  }
  > * {
    margin: 0.4rem;
  }
}
