.smallTitle {
  font-size: 1rem;
  line-height: 1.6rem;
  color: #fff;
  font-weight: 500;
  margin-top: 20rem !important; }

.consultCatalogTitle {
  font-size: 2.6667rem !important;
  line-height: 3.2rem !important;
  color: #fff !important;
  margin-bottom: 1.0667rem !important; }

.container {
  width: 100%;
  position: relative; }
  .container > *:first-child {
    position: absolute;
    top: -8rem;
    right: 0;
    width: 100%;
    padding: 0 7.333rem; }
    .container > *:first-child + * {
      margin-top: 29rem; }
  .container .contentContainer {
    margin-top: 27.7333rem; }
    .container .contentContainer .section {
      padding: 4.2667rem 7.333rem; }
      @media (min-width: 2200px) {
        .container .contentContainer .section {
          padding: 4.2667rem 0.4rem;
          max-width: 2070px;
          margin-right: auto;
          margin-left: auto; } }
      @media (max-width: 2200px) and (min-width: 1600px) {
        .container .contentContainer .section {
          padding: 4.2667rem 0.8rem;
          max-width: 1660px;
          margin-right: auto;
          margin-left: auto; } }
      @media (max-width: 1200px) {
        .container .contentContainer .section {
          padding: 4.2667rem 3rem;
          margin-right: auto;
          margin-left: auto; } }
    .container .contentContainer.brandSection {
      padding: 0.2667rem 7.333rem; }
    .container .contentContainer .sectionTitle {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 1.6rem; }
      .container .contentContainer .sectionTitle h2 {
        font-size: 1.6rem !important; }
    .container .contentContainer .brandGrid {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      justify-content: flex-start;
      grid-gap: 32px; }
      .container .contentContainer .brandGrid .card {
        width: 100%;
        height: 124px; }
      @media (min-width: 768px) {
        .container .contentContainer .brandGrid {
          grid-template-columns: repeat(2, 1fr); } }
      @media (min-width: 992px) {
        .container .contentContainer .brandGrid {
          grid-template-columns: repeat(auto-fill, 124px); } }
      @media (min-width: 1200px) {
        .container .contentContainer .brandGrid {
          grid-template-columns: repeat(auto-fill, 124px); } }
      @media (min-width: 1600px) {
        .container .contentContainer .brandGrid {
          grid-template-columns: repeat(auto-fill, 124px); } }

@media only screen and (max-width: 576px) {
  .container > *:first-child {
    position: initial;
    margin-top: -5rem; }
    .container > *:first-child + * {
      margin-top: 1rem; } }

.noCollections {
  display: flex;
  justify-content: center;
  padding-top: 2vh; }

[class*='ant-modal-title'] {
  padding: 10px; }
