.profilePicture {
  height: 128px;
  width: 128px;
  border-radius: 50%;
  margin-bottom: 2vh;
}

.editButton {
  margin-bottom: 3vh;
  padding-top:1vh;
  text-transform: uppercase;
}

.tabLinks {
  padding-left: 3vh;
  padding-right: 3vh;
  color: black;
  text-transform: uppercase;
  font-family: "Inter";
  font-weight: 600;
  font-size: 12px;
}

@media only screen and (max-width: 576px) {
  .tabLinks {
    padding: 1vh;
    font-size: 9px;
  }
}
.tabLinks:hover {
  color: black;
}

h2 {
  margin-bottom:0;
}

.linkContainer {
  padding-bottom: 1vh;
}

.activeLink {
  padding-bottom: 1vh;
  border-bottom: 2px solid black;
}

.header {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 20vh;
  background-color:white;
}

.backButton {
  color: black;
}

.backButton:hover {
  color: black;
}

.downloadsNumber {
  margin-left: 5px;
  border: 2px solid;
  border-radius: 20px;
  padding: 1px 5px 1px 5px;
}