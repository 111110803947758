@import 'src/styles/variables';
.container {
  display: flex;
  background-color: #FFFFFF;

  .leftSection {
    flex: 1;
    padding: 4%;

    .thumbnail {
      max-width: 100%;
      height: auto;
      max-height: 578px;
    }
  }

  .rightSection {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 50%;
    padding: 4%;

    .textSection {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      h2 {
        margin-bottom: 2%;
      }
    }

    .buttonSection {
      padding: 5%;
      display: flex;
      justify-content: flex-end;
    }
  }
}

.productsPart {
  background-color: #F0F0F0;
  padding: 2% 2% 0 2%;
  border-radius: 8px;
  flex-grow: 1;
  position: relative;
  h3 {
    margin: 0;
  }
  .productsWrapper {
    height: 370px;
    overflow-x: auto;
    display: flex;
    flex-wrap: nowrap;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    &.readOnly {
      overflow-x: hidden;
      opacity: 0.7;

      .productCard {
        pointer-events: none;
      }
    }
    .iconWrapper{
      i:first-child {
        cursor: pointer;
        position: absolute;
        bottom: 1%;
        left: 40%;
      }
      i:nth-child(2) {
        cursor: pointer;
        position: absolute;
        bottom: 1%;
        right: 40%;
      }
    }
    .productCard{
      width: 26%;
      height: 34%;
      flex: 0 0 auto;
      background-color: #FFFFFF;
      margin-bottom: 60px;
      margin-right: 15px;
      margin-left: 15px;
      border-radius: 10px;
      &:hover {
        cursor: pointer;
      }
      @media only screen and (max-width: $breakpoint-sm) {
        width: 40%;
      }
      .productThumbnail {
        width: 100%;
        height: 100%;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }

      }
      .productDescription {
        h4 {
          margin-bottom: 0;
          margin-top: 0.5em;
        }
        div {
          width: 100%;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }
      &.selected {
        position: relative; /* Needed for absolute positioning of the checkmark */
        background-color: rgba(0, 0, 0, 0.1);

        .productThumbnail {
          opacity: 0.1;
        }

        /* Checkmark Style */
        &:after {
          content: '✔'; /* Unicode checkmark */
          position: absolute;
          top: 10px; /* Adjust as needed */
          right: 10px; /* Adjust as needed */
          color: white; /* White checkmark color */
          font-size: 10px; /* Adjust the size of the checkmark */
          font-weight: bold;

          /* Additional styles for circular background */
          background-color: green; /* Green background */
          border-radius: 50%; /* Makes it round */
          width: 15px; /* Width of the circle */
          height: 15px; /* Height of the circle */
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
}

.input {
  background-color: inherit;
  border: 0px !important;
}

.showMinimumProductsMessage {
  color: #D8000C; /* Red color for errors */
  background-color: #FFD2D2; /* Light red/pink background for visibility */
  padding: 10px; /* Add some padding around the text */
  border-radius: 5px; /* Optional: rounded corners for aesthetic */
  border: 1px solid #D8000C; /* Border color similar to text for emphasis */
  display: block; /* Make it a block to occupy its own line */
  margin-top: 10px; /* Space it a bit from the previous elements */
  font-weight: bold; /* Optional: make the message bold */
  text-align: center; /* Center-align the text */
  margin-top:10px;
  margin-bottom: 10px;
}

@media screen and (max-width: 768px) {
  .leftSection {
    display: none;
  }
}
