@import 'src/styles/variables';
.registerAddressFormModal {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .selectPlanRegion {
    border-radius: 5px;
    width:45%;
    @media only screen and (max-width: $breakpoint-sm) {
      width:100%;
    }
    .planName {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 5%;
      padding-bottom: 5%;
      h2 {
        width: auto;
        margin-bottom: 0;
        font-weight: 700;
      }
    }
    .downloadsAlign {
      text-align: center;
      padding-top: 4%;
    }
    .priceGroup {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 5vh;
      h1 {
        width: auto;
        font-weight: 700;
      }
      h2 {
        margin-bottom: 0;
      }
    }
    .input {
      border: none;
    }
    span {
      display: inline-block;
      text-align: center;
    }

    .input:focus {
        outline: none !important;
        box-shadow: none;
    }
    .submitBtnGroup {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      align-items: flex-start;
      padding-top: 15%;
      padding-bottom: 5%;
      align-items: center;
      button {
        width: 50%;
      }
    }
    .priceDescription {
      display: inline-block;
      margin-top: 4%;
      @media only screen and (min-width: $breakpoint-sm + 1) {
        height: 30px;
      }

    }
    .price {
      text-align: left;
      text-transform: uppercase;
    }
    .radioSubscriptionLightCredits, .radioSubscriptionProCredits {

      display: flex;
      justify-content: center;
      padding-bottom: 4%;
      margin-top: 2%;

      .wrapper {
        --font-color-dark: #323232;
        --font-color-light: #FFF;
        --bg-color: #fff;
        --main-color: #323232;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-around;
        background-color: var(--bg-color);
        border-radius: 34px;
        display: flex;
        flex-direction: row;
        padding: 1% 0;
        width: 10vw;
        @media only screen and (max-width: $breakpoint-sm) {
          width: 50vw;
        }
      }
      
      .option {
        position: relative;
      }
      
      .input {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        appearance: none;
        cursor: pointer;
        margin: 0;
      }

      .input:focus {
        outline: none;
      }
      
      .btn {
        height: 4vh;
        width: 2vw;
        @media only screen and (max-width: $breakpoint-sm) {
          width: 10vw;
        }
        background-color: var(--bg-color);
        border-radius: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .span {
        color: var(--font-color-dark);
      }
      
      .input:checked + .btn {
        background-color: var(--main-color);
      }
      
      .input:checked + .btn .span {
        color: var(--font-color-light);
      }
    }
  }

  .form {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
    align-items: baseline;
    [class*='ant-form-item'] {
      margin-bottom: 0;
    }
  }

  .actions {
    margin-top: 2rem;

    button {
      width: calc(50% - 8px);
      &:first-child {
        margin-right: 8px;
      }
      &:last-child {
        margin-left: 8px;
      }
    }
  }
}
@media only screen and (max-width: $breakpoint-sm) {
  .registerAddressForm {
    .selectPlanRegion {
      .radioSubscriptionLightCredits, .radioSubscriptionProCredits {
        .wrapper {
          width: 35vw;
        }
        .btn {
          width: 10.5vw;
        }
      }
    }
  }
  .form {
    flex-direction: column !important;
    margin-top: 20px !important;
  }
}
