@import 'src/styles/variables';

.container {
  width: 100%;
  overflow: hidden;
  > h2 {
    margin: 3rem 0 1rem 0;
  }
  .chartContainer {
    display: flex;
    align-items: center;
    .chart {
      flex: 0.7;
      min-width: 600px;
      min-height: 300px;
    }
    .inputsContainer {
      flex: 0.3;
      margin: 0 2rem;
      display: flex;
      flex-direction: column;
      margin-bottom: auto;
      .select {
        margin-bottom: 1.067em;
      }
      .title {
        font-size: 1.267rem;
        font-weight: bold;
        line-height: 24px;
        margin-bottom: 1.067em;
      }
    }
  }
}
@media only screen and (max-width: $breakpoint-sm) {
  .container {
    .chartContainer {
      .chart {
        flex: 0.7;
        min-width: 100%;
        min-height: 100%;
      }
    }
  }
}
