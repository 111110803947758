@import 'src/styles/variables';

.container {
  background-color: $primary-dark;
  padding: 6.2em 6rem 1.6em;
  position: relative;

  .searchbarContainer {
    width: 50%;
  }

  .button {
    position: absolute;
    right: 6rem;
    bottom: -24px;
  }
}

ul.menu {
  padding: 0;
  box-shadow: 0 8px 24px 0 rgba(0, 0, 0, 0.15);
  .item {
    height: 48px;
    display: flex;
    align-items: center;
    &:hover {
      background-color: rgba(0, 0, 0, 0.05);
    }
    .label {
      font-size: 0.933rem;
      color: #000;
      margin-left: 1.066rem;
    }
  }
}
