@import 'src/styles/variables.scss';

.container {
  padding: 2rem $container-padding;
}

.metrics {
  padding: 0 6rem 2.5em;
  display: flex;
  justify-content: flex-end;
  > * + * {
    margin-left: 2em;
  }
}

.profilePicture {
  height: 128px;
  width: 128px;
  border-radius: 50%
}

.editButton {
  margin-bottom: 5vh;
}

.tabLinks {
  padding-left: 1vh;
  color: black;
}
