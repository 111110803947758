@import 'src/styles/variables';
.container {
  .firstSection {
    padding: 7% 5% 5%;
    background-color: #FFFFFF;
    @media only screen and (max-width: $breakpoint-sm) {
      padding: 20% 5% 5%;
    }

    h1 {
      font-size: 24px;
      margin-bottom: 0;
    }
    .communityGallery {
      padding: 3% 0;
      display: flex;
      flex-wrap: wrap;
      gap: 25px;
      @media only screen and (max-width: $breakpoint-sm) {
        gap: 15px;
      }
      a {
        width: 32%;
        @media only screen and (max-width: $breakpoint-sm) {
          width: 30%;
        }
        img {
          width: 100%;
          border-radius: 8px;
        }
      }
    }
  }
  .secondSection {
    padding: 5% 5%;
    background-color: #dddddd;
    h2 {
      font-size: 24px;
      margin-bottom: 0;
    }
    .addsomeGallery{
      padding: 3% 0;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 25px;
      @media only screen and (max-width: $breakpoint-sm) {
        gap: 10px;
      }
      a {
        width: 48%;
        img {
          width: 100%;
          border-radius: 8px;
        }
      }
    }
  }
  .thirdSection {
    padding: 5% 5%;
    background-color: #000000;
    h2 {
      font-size: 24px;
      margin-bottom: 0;
    }
    h2, span {
      color: #FFFFFF;
    }
    .popularGallery {
      padding: 3% 0;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 25px;
      a {
        width: 48%;
        img {
          width: 100%;
        }
      }
    }
  }
}
.loaderContainer {
  height: 95vh;
}
.sceneContainer {
  display: flex;
  justify-content: space-between;
  padding: 10% 5% 0;
  gap: 25px;
  background-color: #FFFFFF;
  @media only screen and (max-width: $breakpoint-sm) {
    flex-direction: column;
    padding: 20% 5% 10%;
  }
  .picturesPart {
    height: 500px;
    width: 48%;
    position: relative;
    flex-grow: 1;
    @media only screen and (max-width: $breakpoint-sm) {
      width: 100%;
      height: 250px;
    }
    .currentPicture {
      width: 100%;
      border-radius: 8px;
      height: 100%;
      object-fit: cover;
    }
    .leftIcon, .rightIcon {
      width: 10%;
      height: 10%;
      position: absolute;
      bottom: 0;
      top: 0;
      margin: auto 0;
      color: #FFFFFF;
      cursor: pointer;
      svg {
        width: 100%;
        height: 100%;
      }
    }
    .leftIcon {
      left: 0;
    }
    .rightIcon {
      right: 0;
    }
  }
  .productsPart {
    height: 500px;
    width: 48%;
    background-color: #F0F0F0;
    padding: 2%;
    border-radius: 8px;
    flex-grow: 1;
    position: relative;
    padding-bottom: 0;
    overflow-y: auto;
    @media only screen and (max-width: $breakpoint-sm) {
      width: 100%;
      height: 455px;
    }
    h3 {
      margin: 0;
    }
    .productHeader {
      display: flex;
      justify-content: space-between;
    }
    .productsWrapper {
      padding: 3% 0;
      padding-bottom: 0;
      display: flex;
      gap: 3%;
      flex-wrap: wrap;
      width: 100%;
      height: 90%;
      .iconWrapper{
        i:first-child {
          cursor: pointer;
          position: absolute;
          bottom: 1%;
          left: 40%;
        }
        i:nth-child(2) {
          cursor: pointer;
          position: absolute;
          bottom: 1%;
          right: 40%;
        }
      }
      .productCard{
        width: 31%;
        height: 47%;
        @media only screen and (max-width: $breakpoint-sm) {
          height: 40%;
        }
        .productThumbnail {
          width: 100%;
          height: 60%;
          background-color: #FFFFFF;
          cursor: pointer;
          border-radius: 8px;
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            border-radius: inherit
          }
        }
        .productDescription {
          h4 {
            margin-bottom: 0;
            margin-top: 0.5em;
          }
          div {
            width: 100%;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }
        }
      }
    }
  }
}
.adminTableContainer {
  padding: 5%;
  min-height: 90vh;
  .productsColumn {
    cursor: pointer;
  }
  .thumbnail {
    cursor: pointer;
    img {
      width: 60px;
      object-fit: cover;
    }
  }
}

.adminProductsWrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 4%;
  .adminProductCard{
    width: 30%;
    color: inherit;
    h3{
      margin-bottom: 0;
    }
    img {
      width: 100%;
      height: 60%;
      object-fit: scale-down;
      border: 0.5px solid #dddddd;
      border-radius: 8px;
    }
  }
}
.adminMediasWrapper {
  display: flex;
  gap: 20px;
  overflow-x: auto;
  height: 50vh;
  .adminMediaCard {
    padding-bottom: 10px;
    width: 100%;
    img {
      height: 100%;
      width: auto;
      border: 1px solid #dddddd;
      border-radius: 8px;
    }
  }
}

[class*="ant-modal"] {
  min-width: 60vw;
}