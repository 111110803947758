@import '../../styles/colors';
@import '../../styles/variables';

.container {
  height: 6.7rem;
  width: 100%;
  display: flex;
  align-items: center;
  background-color: #151515;
  padding: 0 7.3rem;
  color: white;
  z-index: 1;

  .mobileCopyright {
    display: none;
  }
  .copyright {
    > img {
      height: 3.2rem;
      width: 3.2rem;
      margin: 0 1.6rem;
      color: white;
    }
    > span {
      font-size: 0.933rem;
      font-weight: bold;
    }
  }
  .actions {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    .links {
      display: flex;
      > * {
        text-decoration: none;
        color: white;
        font-size: 0.933rem;
        margin: 0 0.8rem;
        font-weight: bold;
      }
      > div {
        cursor: pointer;
      }
      // margin-right: 0.533rem;
      .socialMedia {
        display: flex;
        justify-content: center;
        a {
          color: white;
        }
      }
      .terms {
        display: flex;
        a {
          color: white;
          font-size: 0.933rem;
          margin: 0 0.8rem;
          font-weight: bold;
        }
      }
    }
    .socialIcon {
      margin: 0 0.533rem;
      font-size: 1.6rem;
    }
    .tiktokIcon {
      background-color: white;
      border-radius: 15px;
    }
  }

  &.isLogin {
    background-color: transparent;
    padding: 0 100px 0 32px;
    @media only screen and (max-width: $breakpoint-md){
      padding: 10px 32px 10px 32px;
    }
  }
}
@media only screen and (max-width: $breakpoint-sm) {
  .container {
    padding: 4% 0 2% 0;
    height: 9rem;
    width: 100%;
    flex-direction: column;
    align-items: unset;
    .copyright {
      display: none;
      > img {
        margin: 0 0.5rem;
      }
      > span {
        font-size: 0.6rem;
      }
    }
    .langs {
      margin-left: 0;
      justify-content: center;
    }
    .actions {
      width: 100%;
      justify-content: center;
      margin: 10px 0;
      .links {
        margin-right: 0;
        display: flex;
        flex-direction: column;
        width: 100%;
        > * {
          flex: 1 1;
          text-align: center;
          font-size: 12px;
          font-weight: normal;
        }
        .terms {
          margin-top: 4%;
          > div {
            display: none;
          }
        }
      }
    }
    .mobileCopyright {
      display: inline-block;
      text-align: center;
      img {
        height: 50%;
        margin-left: 5px;
      }
      span {
        font-size: 12px;
      }
    }
  }
}

.langs {
  display: flex;
  align-items: center;
  margin-left:3vh;

  *:first-child {
    margin-right: 5px;
  }

  *:last-child {
    margin-right: 0;
    margin-left:4px;
  }
  .flag {
    display: flex;
    align-items: center;
    cursor: pointer;
    height: 1rem;
    opacity: 0.5;
    font-size: 0.8rem;
    font-weight: bold;
    color: $grey;
    &.selected {
      opacity: 1;
      color:white;
    }
    > img {
      height: 100%;
      margin-right: 3px;
    }
  }
}
