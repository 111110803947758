.container {
  position: fixed;
  display: block;
  bottom: 2.13rem;
  right: 32px;
  width: 327px;
  background-color: #fff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  &.hidden {
    display: none;
  }
  .header {
    padding: 0 16px;
    height: 48px;
    background-color: #151515;
    display: flex;
    align-items: center;
    border-radius: 8px 8px 0 0;
    .title {
      color: #fff;
      font-size: 1rem;
      font-weight: 500;
    }
    .actions {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      color: #fff;
      .icon {
        svg {
          transition: all ease 0.2s;
        }
      }
      > * {
        margin-right: 0.5rem;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  .estimate {
    padding: 0 16px;
    height: 32px;
    display: flex;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.07);
  }

  .list {
    display: flex;
    flex-wrap: wrap;
    border-radius: 0 0 8px 8px;
    max-height: 32rem;
    overflow-y: auto;
  }

  &.reduced {
    .estimate {
      height: 0;
      overflow-y: hidden;
    }
    .list {
      height: 0;
      overflow-y: hidden;
    }
    .header {
      border-radius: 8px;
    }
  }
}
