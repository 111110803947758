@import 'src/styles/variables';

.header {
  height: 232px;
  background-color: #151515;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 $container-padding;
  padding-top: 64px;

  img.profilePicture {
    height: 112px;
    width: 112px;
    object-fit: cover;
    margin-right: 32px;
    border: 4px solid #ffffff;
    border-radius: 64px;
  }

  .titleContainer {
    display: flex;
    flex-direction: column;

    h1 {
      color: #ffffff;
      font-family: 'Inter';
      font-size: 32px;
      line-height: 40px;
      margin: 8px 0px;
    }

    .email {
      opacity: 0.7;
      color: #ffffff;
      font-family: 'Inter';
      font-size: 15px;
      line-height: 24px;
    }
  }

  .switch {
    flex: 1;
    text-align: right;
    color: #ffffff;
    &.isLoading {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
}

.content {
  padding: 0 $container-padding;
  padding-top: 56px;
  display: flex;
  flex-direction: row;

  h3 {
    color: #000000;
    font-family: 'Inter';
    font-size: 18px;
    font-weight: bold;
    line-height: 32px;
    margin-bottom: 16px;
  }

  .photo {
    min-width: 385px;
  }

  .description {
    margin-left: 136px;
    width: 100%;

    .descriptionBlock {
      border-radius: 4px;
      background-color: #ffffff;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.03);
      padding: 32px;
    }
  }
}
@media only screen and (max-width: $breakpoint-sm) {
  .header {
    height: 100%;
    flex-direction: column;
    padding: 2rem;
    padding-top: 80px;
    align-items: flex-start;
    .titleContainer {
      width: 100%;
    }
    .switch {
      text-align: start;
      padding-top: 1rem;
      width: 100%;
    }
  }
  .content {
    flex-direction: column;
    padding: 0 1rem;
    gap: 1rem;
    .photo {
      min-width: 100%;
    }
    .description {
      margin-left: 0;
    }
  }
}
