@import '../../../styles/colors';

.container {
  width: 100%;
  display: flex;
  justify-content: center;
  &:focus {
    outline: none;
  }
  .circle {
    height: 26.66rem;
    width: 26.66rem;
    border-radius: 50%;
    background-color: #eaeaea;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    .title {
      margin-top: 1rem;
      font-weight: bold;
      font-size: 1.6rem;
    }
    .subtitle {
      font-size: 1rem;
    }
    &.active {
      background-color: darken(#eaeaea, 5%);
    }
  }
}
