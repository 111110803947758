.actions {
  display: flex;
  justify-content: flex-end;
  > * {
    margin-right: 0.5em !important;
  }
}

.svgThumbnail {
  > img {
    padding: 1rem;
    object-fit: fill !important;
  }
}
