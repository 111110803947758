@import "src/styles/variables";

$padding: 16px;
$buttonSize: 84px;

.productGallery {
  padding: $padding;
  .display {
    flex: 1;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100% - #{$padding} - #{$buttonSize});

    @media (max-width: 669px) {
      height: 204px;
    }
    img {
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
    }
  }
  .viewer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .hide {
    display: none;
  }
  .load {
    margin-top: 0.5em;
  }
  .select {
    flex-shrink: 0;
    list-style: none;
    padding: 0;
    margin: $padding 0 0 0;
    display: flex;
    overflow: auto;

    @media (max-width: 669px) {
      max-width: unset;
      padding: 0 0 5px 0;
    }
    > * + * {
      margin-left: 1em;
    }
  }
  .button {
    height: $buttonSize;
    width: $buttonSize;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 4px;
    overflow: hidden;
    border: 1px solid rgba(0, 0, 0, 0.1);
    * {
      color: black;
    }
    &.selected {
      border: solid 2px black;
      outline: 0;
    }
    img {
      width: calc(100% - 2px);
      object-fit: cover;
      &.logo3D {
        width: 2.5em;
        height: 2.5em;
        object-fit: contain;
        border: none;
      }
    }
  }
}
