@import 'src/styles/variables';

.productInformation {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 100em;
  > *:first-child {
    flex: 0.3;
    max-width: 30%;
  }
  > *:last-child {
    flex: 0.6;
  }

  .showFormButton {
    margin-top: 0.8rem;
  }
}
@media only screen and (max-width: $breakpoint-sm) {
  .productInformation {
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    > *:first-child {
      width: 100%;
      max-width: 100%;
    }
    > *:last-child {
      width: 100%;
    }
  }
}
