.productTags {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 100em;
  > *:first-child {
    flex: 0.3;
  }
  > *:last-child {
    flex: 0.6 ;
  }
}
