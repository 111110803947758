@import 'src/styles/variables';

.productFiles {
  position: relative;

  > .add {
    position: absolute;
    width: inherit;
    right: 0;
    top: -4em;
  }

  .section + .section {
    margin-top: 2em;
  }

  h2 {
    margin-bottom: 1em;
  }
  .downloadCart{
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .download {
    font-size: 1.5rem;

    cursor: pointer;
  }

  .progressBarContent {
    margin-left: 10px;
    font-size: 1.5rem;
    opacity: 0;
  }

  .loading {
    font-size: 1.5rem !important;
    color: $primary;
  }

  .fileIcon {
    height: 100%;
    object-fit: scale-down;
    border: solid 1px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
  }
}
[class*='ant-modal-body']{
  p {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
