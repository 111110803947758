.container {
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: 0.3rem;
  background: #fff;
  height: 4.8rem;
  padding: 0.533rem;
  transition: all 0.3s;
  cursor: pointer;
  border-bottom: 1px solid #e8e8e8;
  &:hover {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  }
  .icon {
    height: 3.73rem;
    width: 3.73rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 4px;
  }
  .label {
    color: #000;
    font-size: 1rem;
    font-weight: 500;
    margin-left: 1rem;
  }
  .actions {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    > * {
      margin-right: 0.5rem;
    }
  }
}
