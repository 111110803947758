@import 'src/styles/variables.scss';

.container {
  padding: 2rem $container-padding;
}

.metrics {
  padding: 0 6rem 2.5em;
  display: flex;
  justify-content: flex-end;
  > * + * {
    margin-left: 2em;
  }
}
@media only screen and (max-width: $breakpoint-sm) {
  .container {
    padding: 1rem;
  }
  .metrics {
    padding: 1em;
    justify-content: center;
  }
}
