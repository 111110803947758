@import 'src/styles/variables';

.navbar {
  height: 100%;

  .menu {
    height: 100%;
    background: none;
    border-bottom: none;

    .item {
      color: white;
      text-transform: uppercase;
      font-weight: 500;
      letter-spacing: 0.12em;
      font-size: 0.8rem;
      height: 100%;
      opacity: 0.5;
      padding: 0;
      margin: 0 1.5em;

      &[class*='ant-menu-item-selected'],
      &[class*='ant-menu-item-active'] {
        border-bottom: 2px solid transparent;
        opacity: 1;
      }
      &[class*='ant-menu-item-selected'] {
        border-bottom: 2px solid white;
      }

      a {
        color: inherit;
        height: 100%;
        display: flex;
        align-items: center;
      }

      a:hover {
          color: white;
      }
    }
  }

  &.darkTheme {
    .item {
      color: black;

      &[class*='ant-menu-item-selected'] {
        border-bottom: 2px solid black;
      }
    }
  }
}
@media only screen and (max-width: $breakpoint-sm) {
  .navbar {
    .menu {
      .item {
        font-size: 0.6rem;
        margin: 0 0.5em;
      }
    }
  }
}
