@import '~antd/dist/antd.css';
@import '~@addsome/ui-kit/dist/styles/index.css';

html,
body,
#root {
  height: 100%;
  font-family: 'Inter';
  button {
    font-family: 'GT America';
  }
}

body.fullscreen {
  overflow: hidden;
}
