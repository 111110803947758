@import 'src/styles/variables';

.layout {
  min-height: 100%;
  background-color: #f3f3f3;

  &.whiteBackground {
    background-color: white;
  }

  main {
    // padding-bottom: 4rem;
    display: flex;
    flex-direction: column;
    flex: auto;
  }
}
@media only screen and (max-width: $breakpoint-sm) {
  .layout {
    main {
      // padding-bottom: 2rem;
    }
  }
}
