@import 'src/styles/variables';

div.metricCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2.6rem;
  @media only screen and (max-width: $breakpoint-sm) {
    padding: 2.6rem 1rem;
  }
  width: 25.7rem;
  > * + * {
    margin-top: 1.5rem;
  }

  .container {
    min-height: 28rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    > * + * {
      margin-top: 1.5rem;
    }
    @media only screen and (max-width: $breakpoint-sm) {
      width: 80%;
    }
  }

  .link {
    margin-top: 2.2rem;
  }
}
@media only screen and (max-width: $breakpoint-sm) {
  div.metricCard {
    width: 100%;
  }
}
