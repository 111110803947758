@import 'src/styles/variables';
.productInformationPanel {
  .title {
    font-weight: bold;
  }
  .variationDiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .data {
    position: relative;
    min-height: 100%;
    padding: 16px;
    @media (max-width: $breakpoint-md) {
      padding: 18px;
    }
  }
  dl {
    display: grid;
    grid-template-columns: min-content 1fr;
    grid-gap: 1em 2em;
    font-size: 14px;
    @media (max-width: $breakpoint-md) {
      grid-gap: unset;
      display: flex;
      flex-direction: column;
    }
    dt {
      font-weight: bold;
      grid-column: 1;
    }
    dd {
      margin-left: 0;
      grid-column: 2;
      @media (max-width: 669px) {
        grid-column: 1;
      }
      word-break: break-word;
    }
  }
  .reportText {
    position: absolute;
    right: 3%;
    font-weight: 500;
    text-decoration: underline;
    bottom: 2%;
    cursor: pointer;
    @media (max-width: $breakpoint-md) {
      bottom: 1%;
    }
  }

  .selectContainer {
    overflow: hidden;
  }

  img {
    height: 5em;
    width: 5em;
    object-fit: contain;
    border: solid 1px #d1d1d1;
    border-radius: 0.3em;
    @media only screen and (max-width: $breakpoint-md){
      height: 3em;
      width: 3em;
      margin: 10px 0;
    }
  }

  .heart {
    width: 20px;
    height: 20px;
    border: none;
    cursor: pointer;
    transition: all 0.3s ease;
  }

  .heart:hover {
    transform: scale(1.2);
  }

  .headerGroup {
    display: flex;
    gap: 10px;
  }

  .header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 5em;
    @media (max-width: $breakpoint-md) {
      min-height: 2.4rem;
      margin-bottom: 10px;
    }
    word-break: break-all;
    word-break: break-word;
  }

  .title {
    display: flex;
    justify-content: space-between;
    @media (max-width: $breakpoint-md) {
      font-size: 18px;
      align-items: center;
      gap: 20px;
    }
  }

  .name {
    font-weight: bold;
    margin: 0;
    line-height: normal;
  }
  .price {
    opacity: 0.4;
  }

  .desc {
    white-space: pre-wrap;
  }

  .dimensions {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .productButtonContainer {
    position: sticky;
    bottom: 16px;
    background-color: white;
    &::before {
      content: "";
      position: absolute;
      top: -32px;
      right: -16px;
      bottom: -17px;
      left: -16px;
      background: linear-gradient(to top, white calc(100% - 16px), transparent);
      @media (max-width: 669px) {
        top: 0px;
        width: 80%;
      }
    }
  }

  .productButton {
    width: 100%;
  }
}

.alignCenter {
  text-align: center!important;
  padding-top: 20px;
  padding-bottom: 20px;
}

.infoCardProductModal {
  position: absolute;
  background-color: white;
  height: auto;
  z-index: 3000;
  width: 90%;
  top: 110px;
  left: 20px;
  padding: 10px 17px;
  border-style: outset;
  border-radius: 5px;
  border: 2px solid #000000;
}
.productFiles {
  display: flex;
  flex-direction: column;
  // background:rgb(243, 243, 243);
  @media (max-width: $breakpoint-md) {
    h2 {
      font-size: 14px;
    }
  }
}
.fileCard {
  height: 6vh;
  margin-top: 1%;
  margin-bottom: 1%;
  padding: 1%;
  border-radius: 5px;
  display: flex;
  align-items: center;
  background:rgb(243, 243, 243);
  @media (max-width: $breakpoint-md) {
    font-size: 12px;
  }
  img {
    height: 90%;
    width: 8%;
    background: white;
    margin-right: 10px;
    @media (max-width: $breakpoint-md) {
      height: 75%;
      width: 10%;
    }
  }
  .fileName {
    width: 60%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    @media (max-width: $breakpoint-md) {
      width: 45%;
    }
  }
  .fileSize {
    width: 15%;
    @media (max-width: $breakpoint-md) {
      width: 30%;
      text-align: center
    }
  }
  .fileExtension {
    width: 10%;
    @media (max-width: $breakpoint-md) {
      width: 12%;
    }
  }
  .fileIcon {
    width: 5%;
    height: 50%;
    cursor: pointer;
    i {
      width: 100%;
      height: 100%;
      svg {
        height: 100%;
        width: 100%;
      }
    }
  }
  .progressBarContent {
    margin-left: 5px;
    font-size: 1.5rem;
    opacity: 0;
    @media (max-width: $breakpoint-md) {
      font-size: 1rem;
    }
  }
}
.remainingDownloads {
  min-width: 65px;
  span {
    background:rgb(243, 243, 243);
    padding: 1%;
    border-radius: 5px;
    font-weight: 500;
  }
}
.loginRegisterModal {
  [class*=ant-modal-title] {
    padding: 35px 45px 10px;
    font-weight: 400!important;
  }
  .ant-modal-title {
    padding: 35px 45px 10px;
    font-weight: 400!important;
  }

}
.backWrapper{
  display: flex;
  align-items: flex-start;
  padding: 1% 0;
  h5 {
    margin: 0;
  }
  .backToInfo {
    border-radius: 5px;
    padding-right: 2%;
    height: 4vh;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    text-align: left!important;
    @media (max-width: $breakpoint-md) {
      padding-right: 5%;
    }
    svg {
      height: 100%;
      width: 2vh;
    }
  }
}
