$thumbnail_size: 4.267rem;
$thumbnail_small_size: 3.7rem;
$thumbnail_large_size: 6rem;

.container {
  cursor: pointer;

  .noThumbnail {
    &.white {
      background-color: #f2f2f2;
      & > span {
        color: black;
      }
    }

    height: 93px;
    width: 93px;
    border-radius: 4px;
    background-color: #05444f;
    display: flex;
    justify-content: center;
    align-items: center;
    & > span {
      color: #ffffff;
      font-family: 'Inter';
      font-size: 20px;
      font-weight: 500;
      line-height: 32px;
      text-align: center;
      text-decoration: none !important;
    }

    &.small {
      width: $thumbnail_small_size;
      height: $thumbnail_small_size;
    }

    &.large {
      width: $thumbnail_large_size;
      height: $thumbnail_large_size;
    }
  }

  a {
    text-decoration: none !important;
  }
}
