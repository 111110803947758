@import 'src/styles/variables.scss';

.uploadBigSquare {
  width: $MEDIA_WIDTH;
  height: $MEDIA_HEIGHT;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border-radius: 0.4em;
  border: dashed 2px rgba(0, 0, 0, 0.1);
  cursor: pointer;

  transition: all 0.2s ease-out;

  &:hover {
    border: dashed 2px rgba(0, 0, 0, 0.2);
  }
}

.icon {
  font-size: 3em;
}