@import 'src/styles/variables';

.profilePicture {
  height: 275px;
  width: 385px;
  border-radius: 8px;
  background: #ffffff;
  > img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
  margin-bottom: 8px;
}

.uploadButton {
  margin-bottom: 8px;
}

.downloadButton {
  width: 100%;
}

.subHeader {
  height: 72px;
  background: none;
  border-bottom: none;
  display: flex;
  flex-direction: row;

  &[class*='ant-menu'] {
    padding: 0 $container-padding;
  }

  .item {
    color: white;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 0.12em;
    font-size: 0.8rem;
    height: 100%;
    opacity: 0.5;
    padding: 0;
    margin: 0 1.5em;
    color: inherit;
    height: 100%;
    display: flex !important;
    align-items: center;

    &[class*='ant-menu-item']:hover {
      color: #000000;
      border-bottom: 2px solid #000000;
    }

    &[class*='ant-menu-item-selected'],
    &[class*='ant-menu-item-active'] {
      border-bottom: 2px solid transparent;
      opacity: 1;
      color: #000000;
    }
    &[class*='ant-menu-item-selected'] {
      border-bottom: 2px solid #000000;
    }
  }
}

.passwordEditContainer {
  padding: 0 $container-padding;
  padding-top: 56px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .column {
    width: 593px;

    h3 {
      color: #000000;
      font-family: 'Inter';
      font-size: 18px;
      font-weight: bold;
      line-height: 32px;
      margin-bottom: 16px;
    }

    .block {
      border-radius: 4px;
      background-color: #ffffff;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.03);
      padding: 32px;

      .buttonChangePassword {
        width: 100%;
      }
    }
  }
}
@media only screen and (max-width: $breakpoint-sm) {
  .profilePicture {
    height: 100%;
    width: 100%;
  }
  .passwordEditContainer {
    padding: 0 1rem;
    .column {
      width: 100%;
    }
  }
}
