@import 'src/styles/variables';

.container {
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  justify-content: flex-start;
  grid-column-gap: 32px;
  grid-row-gap: 26px;

  .card {
    width: 93px;
  }
}
