@import 'src/styles/colors';
@import 'src/styles/variables';

.resetPassword {
  display: flex;
  flex-direction: column;

  [class*='ant-form-item'][class*='ant-row'] {
    margin-bottom: 0.3em;
  }

  .submit {
    margin-top: 2.5em;
  }

  .success {
    margin-top: 1em;
    text-align: center;
  }
}

.error {
  color: $error;
}

