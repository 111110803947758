.productFiles {
  position: relative; }
  .productFiles > .add {
    position: absolute;
    width: inherit;
    right: 0;
    top: -4em; }
  .productFiles .section + .section {
    margin-top: 2em; }
  .productFiles h2 {
    margin-bottom: 1em; }
  .productFiles .downloadCart {
    display: flex;
    flex-direction: row;
    align-items: center; }
  .productFiles .download {
    font-size: 1.5rem;
    cursor: pointer; }
  .productFiles .progressBarContent {
    margin-left: 10px;
    font-size: 1.5rem;
    opacity: 0; }
  .productFiles .loading {
    font-size: 1.5rem !important;
    color: #151515; }
  .productFiles .fileIcon {
    height: 100%;
    object-fit: scale-down;
    border: solid 1px rgba(0, 0, 0, 0.1);
    border-radius: 4px; }

[class*='ant-modal-body'] p {
  padding-top: 20px;
  padding-bottom: 20px; }
