.multiStepNavigation {
  border-top: solid 1px rgba(0,0,0,0.1);
  display: flex;
  padding: 2.1rem;
  > * {
    flex: 1
  }
  > * + * {
    margin-left: 1rem;
  }
}
