@import 'src/styles/variables';

.visuals {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  > :not(h2) + * {
    margin-top: 1em;
  }

  .price {
    color: $primary;
  }
}
