@import '../../styles/variables.scss';
.cardWrapper {
  display: flex;
  gap: 20px;
  @media (max-width: $breakpoint-sm) {
    flex-direction: column;
    align-items: center;
  }
  .standardCard, .packCard {
    background-color: white;
    padding: 5% 6%;
    width: 50%;
    @media (max-width: $breakpoint-sm) {
      width: 100%;
    }
    text-align: center;
    border: 0.5px solid;
    .loadingContainer {
      margin: 0!important;
    }
    .subscriptionDescription {
      height: 100px;
      display: flex;
      align-items: center;
    }
    .priceGroup {
      display: flex;
      align-items: baseline;
      height: 10vh;
      justify-content: center;
      h1 {
        width: auto;
      }
    }
    .submitBtnGroup {
      h6 {
        height: 40px;
      }
      button {
        margin-top: 5%;
      }
    }
    .radioSubscriptionStandardCredits, .radioSubscriptionProCredits {

      display: flex;
      justify-content: center;

      .wrapper {
        --font-color-dark: #323232;
        --font-color-light: #FFF;
        --bg-color: #fff;
        --main-color: #323232;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-around;
        background-color: var(--bg-color);
        border: 2px solid var(--main-color);
        border-radius: 34px;
        display: flex;
        flex-direction: row;
        padding: 1% 0;
        width: 13vw;
        margin-bottom: 2%;
        @media (max-width: $breakpoint-sm) {
          width: 35vw;
        }
      }
      
      .option {
        position: relative;
      }
      
      .input {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        appearance: none;
        cursor: pointer;
        margin: 0;
        border: none;
      }

      .input:focus {
        outline: none;
        box-shadow: none;
      }
      
      .btn {
        height: 4vh;
        width: 2.5vw;
        background-color: var(--bg-color);
        border-radius: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        @media (max-width: $breakpoint-sm) {
          width: 10vw;
        }
      }
      
      .span {
        color: var(--font-color-dark);
      }
      
      .input:checked + .btn {
        background-color: var(--main-color);
      }
      
      .input:checked + .btn .span {
        color: var(--font-color-light);
      }
    }
  }
}