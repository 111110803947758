@import '../../../styles/colors';
@import '../../../styles/variables';

.container {
  h3 {
    margin-bottom: 1rem;
  }
  .row {
    td {
      strong {
        color: $black;
      }
      span {
        color: $black-50;
      }
      &:first-child {
        padding-left: 2em;
      }
    }
    &.yellow {
      td {
        &:first-child {
          border-left: 4px solid $category-yellow;
        }
      }
    }
    &.blue {
      td {
        &:first-child {
          border-left: 4px solid $category-blue;
        }
      }
    }
    &.cyan {
      td {
        &:first-child {
          border-left: 4px solid $category-cyan;
        }
      }
    }
    &.primary {
      td {
        &:first-child {
          border-left: 4px solid $primary;
        }
      }
    }
    &.ligthGrey {
      td {
        &:first-child {
          border-left: 4px solid $category-light-grey;
        }
      }
    }
    &.darkBlue {
      td {
        &:first-child {
          border-left: 4px solid $category-dark-blue;
        }
      }
    }
  }
}
@media only screen and (max-width: $breakpoint-sm) {
  .container {
    .row {
      td {
        &:first-child {
          padding-left: 0.5rem;
        }
      }
    }
  }
}
