@import 'src/styles/variables';

.brandInformationPanel {
  .data {
    min-height: 100%;
  }

  dl {
    display: grid;
    grid-template-columns: minmax(min-content, 10rem) 1fr;
    grid-gap: 1em 2em;
    font-size: 1rem;
    dt {
      font-weight: bold;
      grid-column: 1;
    }
    dd {
      margin-left: 0;
      grid-column: 2;
    }
  }

  .title {
    font-size: 1.4em;
    margin-bottom: 1.5em;
  }
}
@media only screen and (max-width: $breakpoint-sm) {
  .brandInformationPanel {
    dl {
      dd {
        width: 90%;
      }
    }
  }
}
