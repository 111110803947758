@import 'src/styles/variables';

.name {
  color: $primary;
}
.end {
  margin-top: 24px;
  text-align: center;
  color: #333;
  font-size: 1rem;
}
.stepZeroContainer {
  width: 100% !important;
}