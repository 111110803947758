@import 'src/styles/variables';

.container {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  justify-content: flex-start;
  grid-gap: 32px;

  .card {
    width: 100%;
    height: 124px;
  }

  @media (min-width: $breakpoint-md) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: $breakpoint-lg) {
    grid-template-columns: repeat(auto-fill, 124px);
  }

  @media (min-width: $breakpoint-xl) {
    grid-template-columns: repeat(auto-fill, 124px);
  }

  @media (min-width: $breakpoint-2xl) {
    grid-template-columns: repeat(auto-fill, 124px);
  }
}
