@import 'src/styles/variables';

.container {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  justify-content: flex-start;
  grid-gap: 32px;

  &.hasSelectedCards {
    margin-bottom: 72px;
  }

  .gridItem {
    height: 100%;
  }

  .card {
    width: 100%;
    height: 100%;
  }

  @media (min-width: $breakpoint-md) {
    grid-template-columns: repeat(2, 1fr);
  }

  //lg
  @media (min-width: $breakpoint-lg) {
    grid-template-columns: repeat(auto-fill, 300px);
  }

  //xl
  @media (min-width: $breakpoint-xl) {
    grid-template-columns: repeat(auto-fill, 385px);
  }

  //xl
  @media (min-width: $breakpoint-2xl) {
    grid-template-columns: repeat(auto-fill, 385px);
  }
}
@media only screen and (max-width: $breakpoint-sm) {
  .container {
    justify-content: center;
  }
}
