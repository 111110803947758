@import 'src/styles/colors';
@import 'src/styles/variables';

.addsomeLogo {
  width: 300px;
  margin-top: 5rem;
}

.smallTitle {
  font-size: 1.2rem !important;
  line-height: 1.6rem;
  color: $white;
  font-weight: 500;
  margin-top: 1rem !important;
}

.allContainer {
  // height: 100%;
  width: 100%;
  position: relative;
  > *:first-child {
    position: absolute;
    top: -37.9333rem;
    right: 0;
    width: 100%;
    padding: 0 7.333rem;
    & + * {
      margin-top: 29rem;
    }

    @media only screen and (max-width: $breakpoint-sm) {
      padding: 0 1.333rem;
      position: initial;
    }
  }

  .contentContainer {
    margin-top: 27.7333rem;

    @media only screen and (max-width: $breakpoint-sm) {
      margin-top: 0;
    }

    .sectionCategory:nth-child(1) {
      padding-bottom: 0;
    }
    .sectionCategory {
      display: flex;
      width: 1220px;
      margin-right: auto;
      margin-left: auto;
      padding: 4.2667rem 0;
      justify-content: space-between;
      >a {
        width: 23%;
        @media only screen and (max-width: $breakpoint-sm) {
          width: 45%;
        }
      }
      >div {
        div:nth-child(2) {
          grid-template-columns: repeat(auto-fill, 280px) !important;
        }
      }
      @media only screen and (max-width: $breakpoint-sm) {
        flex-wrap: wrap;
        width: auto;
        justify-content: space-around;
      }
    }
  }
}
@media only screen and (max-width: $breakpoint-sm) {
  .container {
    margin-top: -5rem;
    > *:first-child {
      position: initial;
      padding: 0 1rem;
    }
    .contentContainer {
      margin-top: 2rem;
    }
  }
}
