@import 'src/styles/colors';
@import 'src/styles/variables';

form.form {
  display: flex;
  flex-direction: column;
  width: 100%;

  [class*='ant-form-item'][class*='ant-row'] {
    margin-bottom: 0.3em;
  }

  .submit {
    margin-top: 2.5em;
    i {
      margin-right: 8px;
    }
  }

  .error,
  .validation {
    text-align: center;
  }

  .error {
    color: $error;
  }

  .validation {
    color: $primary;
  }

  a {
    color: $primary;
  }

  .noAccount {
    margin-top: 24px;
    text-align: center;
  }
}
