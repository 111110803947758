@import 'src/styles/variables';

.container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  > * {
    margin-right: 2rem;
    &:last-child {
      margin-right: 0;
    }
  }
}
@media only screen and (max-width: $breakpoint-sm) {
  .container {
    flex-direction: column;
    align-items: center;
    > * {
      margin-right: 0;
    }
  }
}
