.deleted {
  background: #f0b7b7;
  td {
    background: none !important;
  }
}

.actions {
  display: flex;
  justify-content: flex-end;
  > * + * {
    margin-left: 0.5em;
  }
}