@import 'src/styles/variables.scss';

.brandsPage {
  &.noTab {
    padding: 2rem $container-padding;
  }
}
@media only screen and (max-width: $breakpoint-sm) {
  .brandsPage {
    &.noTab {
      padding: 1rem;
    }
  }
}
