@import '../../styles/colors';
@import '../../styles/variables';

.mainContainer {
  position: relative;
  display: flex;
  align-items: center;

  .remainingDownloads {
    color: white;
    font-weight: bold;
    font-size: 18px;
    margin-right: 10px;
    display: flex;
    align-items: center;
  }

  .container {
    cursor: pointer;
    display: flex;
    align-items: center;

    .avatar {
      border-radius: 50%;
      overflow: hidden;
      > img {
        height: 2.133rem;
        width: 2.133rem;
        object-fit: cover;
      }
    }
  }

  .userPopup {
    position: fixed;
    width: 344px;
    border-radius: 4px;
    background-color: #ffffff;
    box-shadow: 0 8px 24px 0 rgba(0, 0, 0, 0.15);
    top: 0;
    height: 100vh;
    right: 0px;
    opacity: 0;
    display: flex;
    flex-direction: column;
    z-index: 100;

    .upperBlock {

      padding-bottom: 16px;
      box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.10);
      margin-bottom: 16px;
      padding: 32px;
      background: #F0F0F0;

      i {
        width: 100%;
        text-align: right;
        color: black;
        cursor: pointer;
      }

      .upperBlockDetailsContainer {
        display: flex;
        flex-direction: row;
        align-items: center;
      }

      .profilePicture {
        width: 80px;
        height: 80px;
        object-fit: cover;
        margin-right: 16px;
        border-radius: 50px;
      }

      .userCredits {
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: center;
        justify-content: center;
      }

      .userInfos {
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: center;

        .userName {
          color: #292c33;
          font-family: 'Inter';
          font-size: 15px;
          font-weight: 500;
          line-height: 24px;
          margin-bottom: 0px;
        }

        .userEmail {
          opacity: 0.5;
          color: #292c33;
          font-family: 'Inter';
          font-size: 12px;
          line-height: 16px;
        }

        .seeProfileButton {
          width: 100%;
        }
      }
    }

    .actions {
      display: flex;
      flex-direction: column;
      gap: 30px;
      padding: 32px;

      .action {
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 10px;

        h3 {
          margin: 0;
        }

        svg{
          width: 25px;
          height: 25px;
          color: black;
        }
      }

      .langs {
        display: flex;
        > * {
          margin-right: 15px;
          &:last-child {
            margin-right: 0;
          }
        }
        .flag {
          display: flex;
          align-items: center;
          cursor: pointer;
          height: 1rem;
          opacity: 0.5;
          font-size: 0.8rem;
          font-weight: bold;
          color: $black;
          &.selected {
            opacity: 1;
          }
          > img {
            height: 100%;
            margin-right: 3px;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: $breakpoint-sm) {
  .mainContainer {
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
    .remainingDownloads {
      font-size: 12px;
      margin-right: 0;
      i {
        display: none;
      }
    }
    .container {
      .avatar {
        > img {
          height: 1.5rem;
          width: 1.5rem;
        }
      }
    }
  }
}
