@import 'src/styles/variables';
.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 5%;
  background-color: #FFFFFF;

  .header {
    width: 100%;
    background-color: #F2F2F2;
    padding: 2%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px 10px 0 0;
  }

  .content {
    padding: 1%;
    text-align: left;
  }

  .uploadContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 2%;
    width: 100%;
    background-color: #F2F2F2;
    border-radius: 0 0 10px 10px;
    .imageContainer{
      @media only screen and (max-width: $breakpoint-sm) {
        width: 100%;
      }
    }
  }

  .uploadedImage {
    height: 19rem;
    width: 27.5rem;
    margin-bottom: 4%;

  }
}

.uploadComponent {
  background-color: #FFFFFF;
  height: 19rem !important;
  width: 27.5rem !important;
}
[class*=big-square-upload] {
  width: 100%;
  height: 19rem;
}

.imageContainer {
  position: relative;
  margin-right: 1.5%;
  margin-bottom: 1.5%;
}

.deleteButton {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  border: none;
  padding: 10px; /* Adjusted padding for better visibility */
  background-color: #ff6666; /* A red background for delete button */
  color: white; /* White color for the icon */
  border-radius: 50%; /* Makes the button circular */
  transition: transform 0.2s ease, background-color 0.2s ease, color 0.2s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */

  &:hover {
    transform: scale(1.2);
    background-color: #ff4d4d; /* Slightly different shade on hover */
    color: #fff; /* Change color on hover for more effect */
  }
}


.minimumUploadMessage {
  color: #D8000C; /* Red color for errors */
  background-color: #FFD2D2; /* Light red/pink background for visibility */
  padding: 10px; /* Add some padding around the text */
  border-radius: 5px; /* Optional: rounded corners for aesthetic */
  border: 1px solid #D8000C; /* Border color similar to text for emphasis */
  display: block; /* Make it a block to occupy its own line */
  margin-top: 10px; /* Space it a bit from the previous elements */
  font-weight: bold; /* Optional: make the message bold */
  text-align: center; /* Center-align the text */
}

.croppedImage {
  width: 27.5rem; /* Same width as before */
  height: 19rem; /* Same height as before */
  background-size: cover; /* This ensures the image covers the whole div */
  background-position: center; /* Centers the image */
  background-repeat: no-repeat; /* Prevents the image from repeating */
  display: block; /* To ensure proper rendering */
  @media only screen and (max-width: $breakpoint-sm) {
    width: 100%;
  }
}
