.objectProductForm {
  max-width: 60em;

  position: relative;

  .add {
    position: absolute;
    top: 1%;
    right: 3%;
  }

  > * + * {
    margin-top: 1em;
  }

  [class*='ant-form-item'][class*='ant-row'] {
    margin-bottom: 0.3em;
  }

  [class*='ant-tabs-bar'] {
    padding: 0 !important;
  }

  .directionRow {
    flex-direction: row;
  }
}
