@import 'src/styles/colors';
@import 'src/styles/variables';

.containerSectionGreen {
  background-color: #151515;
}

$breakpoint: 1920px;

.section {
  // This width is equal to the 3 Metrics cards width
  width: 1220px;
  margin-right: auto;
  margin-left: auto;

  padding: 4.2667rem 0;

  // After 1900px we display 4 product cards and 11 brand cards
  // Calculation is based on 11 brands cards + their space
  // 124 * 11 + 32 * 10 = 1684px
  @media (min-width: $breakpoint) {
    width: 1684px;
  }

  &.sectionGreen {
    background-color: #151515;

    .sectionTitle h2 {
      color: $white;
    }
  }

  &.noPaddingTop {
    padding-top: 0;
  }

  &.brandSection {
    padding: 0.2667rem 0;
    padding-bottom: 4.2667rem;
  }

  .sectionTitle {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 1.6rem;

    & h2 {
      font-size: 1.6rem !important;
    }
  }

  // Product grid responsiveness
  .productGrid {
    // We override the size of this grid, on the homepages we always want big pages
    grid-template-columns: repeat(auto-fill, 385px) !important;

    &.twoLines {
      & > * {
        display: none;

        // Per default we display 3 cards per line, so 6 cards
        &:nth-child(-n + 6) {
          display: block;
        }

        @media (min-width: $breakpoint) {
          // After the breakpoint we display 4 cards per line, so 8 cards
          &:nth-child(-n + 8) {
            display: block;
          }
        }
      }
    }

    &.oneLine {
      & > * {
        display: none;

        // Per default we display 3 cards per line
        &:nth-child(-n + 3) {
          display: block;
        }

        @media (min-width: $breakpoint) {
          // After the breakpoint we display 4 cards per line
          &:nth-child(-n + 4) {
            display: block;
          }
        }
      }
    }
  }
  // Product grid responsiveness for smaller products
  .smallProductGrid {
    // We override the size of this grid, on the homepages we always want big pages
    grid-template-columns: repeat(auto-fill, 200px) !important;

    &.twoLines {
      & > * {
        display: none;

        // Per default we display 5 cards per line, so 10 cards
        &:nth-child(-n + 10) {
          display: block;
        }

        @media (min-width: $breakpoint) {
          // After the breakpoint we display 4 cards per line, so 8 cards
          &:nth-child(-n + 8) {
            display: block;
          }
        }
      }
    }

    &.oneLine {
      & > * {
        display: none;

        // Per default we display 3 cards per line
        &:nth-child(-n + 3) {
          display: block;
        }

        @media (min-width: $breakpoint) {
          // After the breakpoint we display 4 cards per line
          &:nth-child(-n + 4) {
            display: block;
          }
        }
      }
    }
  }

  .brandGrid {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, 124px);
    justify-content: flex-start;
    grid-gap: 32px;

    .card {
      width: 100%;
      height: 124px;
    }

    @media (min-width: $breakpoint-md) {
      grid-template-columns: repeat(auto-fill, 124px);
    }

    @media (min-width: $breakpoint-lg) {
      grid-template-columns: repeat(auto-fill, 124px);
    }

    @media (min-width: $breakpoint-xl) {
      grid-template-columns: repeat(auto-fill, 124px);
    }

    @media (min-width: $breakpoint-2xl) {
      grid-template-columns: repeat(auto-fill, 124px);
    }

    & > * {
      display: none;

      // Per default we display 8 brand cards per line, so 16
      &:nth-child(-n + 16) {
        display: block;
      }

      @media (min-width: $breakpoint) {
        // Per default we display 11 brand cards per line, so 22
        &:nth-child(-n + 22) {
          display: block;
        }
      }
    }
  }
}
@media only screen and (max-width: $breakpoint-sm) {
  .section {
    width: 100%;
    padding: 3rem 1rem;
    .productGrid {
      grid-template-columns: repeat(auto-fill, 100%) !important;
    }
    &.noPaddingTop {
      padding-top: 1rem;
    }
    &.mobileHidden {
      display: none;
    }
    .brandGrid {
      justify-content: center;
    }
  }
}
