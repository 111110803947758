@import 'src/styles/variables';

.subscriptionSection{
  padding: 5%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  @media only screen and (max-width: $breakpoint-md) {
    flex-direction: column;
  }
}

.subscriptionBox {
  padding: 1%;
  border: 2px solid black;
  height: 35em;
  width: 22%;
  position: relative;
  border-radius: 20px;
  @media only screen and (max-width: $breakpoint-md) {
    width: 80%;
    margin-bottom: 5%;
    height: 40vh;
  }
}

.subscriptionBox:hover {
  opacity: 0.8;
}

.subscriptionTitle {
  font-size: 2vw;
  color: $primary;
  font-weight: bold;
  text-align: center;
}

.subscriptionList {
  margin: 2em 0;
  padding-inline-start: 15px;
  .subscriptionListHeight{
    height: 3em;
  }
}

.packageList {
  text-align: center;
}

.upgradeBtn:hover {
  transform: scale(1.08);
  cursor: pointer;
}
.upgradeBtn {
  position: absolute!important;
  bottom: 5%;
  left: auto;
  right: auto;
  width: 90%;
}
.upgradeButton {
  margin-top: 1.25rem;
  box-shadow: inset 0 0 0 2px #f1f1f1 !important;
  color: $primary !important;
  font-size: 1.2rem!important;
  font-weight: bold!important;
  text-transform: inherit!important;
  border: 2px solid !important;
}

.upgradeButton:hover {
  background-color: #f1f1f1 !important;
}
.upgradeButton:focus {
  background-color: white !important;
}

.currentBtn {
  position: absolute;
  bottom: 5%;
  left: auto;
  right: auto;
  width: 90%;
  box-shadow: inset 0 0 0 1px grey !important;
  color: grey !important;
}

.currentBtn:hover {
  box-shadow: inset 0 0 0 1px grey!important;
  color: grey!important;
  background-color: transparent!important;
  cursor: grab!important;
}

.subscriptionPrice {
  font-size: 3.5em;
  font-weight: bold;
  text-align: center;
  margin: 1.6em 0;
  @media only screen and (max-width: $breakpoint-md) {
    margin: 0.6em 0;
  }
}

.upgradeBtnWrapper{
  @media only screen and (max-width: $breakpoint-md) {
    display: flex;
    justify-content: center;
  }
}

.packPrice {
  font-size: 3.5em;
  font-weight: bold;
  text-align: center;
  margin: 1.6em 0;
  margin-top: 1.3em;
  @media only screen and (max-width: $breakpoint-md) {
    margin-top: 0;
    margin: 0.6em 0;
  }
}

.downgradeSubscription {
  position: absolute;
  bottom: 5%;
  left: auto;
  right: auto;
  width: 90%;
  background-color: grey!important;
  box-shadow: inset 0 0 0 1px grey !important;
  color: white!important;
}

.loaderContainer {
  height: 100vh;
}

.downgradeSubscription:hover {
  cursor: not-allowed;
}

.checkIcon {
  font-size: 20rem;
  color: $primary;
  margin-top: 4rem;
}

.iconContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding-bottom: 4%;
}

.subtitle {
  font-size: 2rem;
  color: $primary;
  font-weight: bold;
  text-align: center;
  margin-top: 3rem;
}
.errorText {
  color: red;
  text-align: center;
}