@import 'src/styles/variables';

.name {
  color: $primary;
}

.description {
  margin: 24px 0;
}

.firstDescription {
  position: relative;
  padding-bottom: 12px;
  margin-bottom: 12px;
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 24px;
    height: 2px;
    background: $primary;
  }
}
