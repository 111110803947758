@import '../../styles/colors';

.container {
  padding: 16px;
  div[class*='ant-notification-notice-with-icon'] {
    display: flex;
    align-items: baseline;
  }
  div[class*='ant-notification-notice-description'] {
    margin-left: 5px!important;
    font-size: 16px!important;
    color: black;
  }
  div[class*='ant-notification-notice-message'] {
    margin-bottom: 0;
    margin-left: 40px;
    color: rgba(0, 0, 0, 0.65);
    padding-right: 0px!important;
  }
  i[class*='anticon anticon-check-circle-o ant-notification-notice-icon ant-notification-notice-icon-success'] {
    color: $primary;
    margin-left: 0;
  }
}
