.deleted {
  background: #f0b7b7;
  td {
    background: none !important;
  }
}

.actions {
  display: flex;
  justify-content: flex-end;
  > * {
    margin-right: 0.5em;
  }
}
.enabled {
  color: white;
  margin-top: auto;
}

.richLeft {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: white;
  text-align: left;
  height: 6rem;
  > * + * {
    margin-left: 2em;
  }
}

.richRight {
  display: flex;
  flex-direction: column;
  align-items: right;
  justify-content: space-between;
}

.position {
  position: relative !important;
}

.enableCell {
  position: relative !important;
  display: flex;
  width: 100px;
}
.tableStyle {
  .ant-table-filter-icon {
    position: relative !important;
    width: auto !important;
  }

  i,
  svg {
    position: relative !important;
  }

  // Commented because it's affecting the filer icon
  //th:last-of-type {
  //  i,
  //  svg :last-of-type {
  //    position: absolute !important;
  //    margin-right: 30px;
  //  }
  //}

  th {
    padding: 16px 7px !important;
  }
}
.productName {
  cursor: pointer;
}
