// styles.scss

.container {
  display: flex;
  flex-direction: column;
  background-color: #FFFFFF;

  > * {
    flex-grow: 1;
  }
}

[class*="ant-modal"] {
  min-width:5vw;
}
