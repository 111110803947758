.noProducts {
  display: flex;
  justify-content: center;
  padding-top: 2vh;
}

.details {
  display: flex;
  justify-content: space-between;
  padding-top:2vh;
}

.collectionName {

}

.section {
  margin-right: auto;
  margin-left: auto;
}

.inputStyle {
  display: inline-block;
  border: none;
  outline: none;
  padding: 0;
  font-size: inherit;
  background-color: transparent;
  box-shadow: none;
}

.inputStyle:hover,
.inputStyle:focus {
  border: none;
  outline: none;
  box-shadow: none;
}

.editName {
  display: flex;
  align-items: baseline;
}

.editButton {
  background: #f3f3f3;
  border: none;
  outline: none;
  box-shadow: none;
}

.editButton:hover,
.editButton:focus {
  background: #f3f3f3;
  border: none;
  outline: none;
  box-shadow: none;
}