@import 'src/styles/colors';

.registerInitForm {
  width: 100%;
  [class*='ant-form-item'] {
    margin-bottom: 0;
  }

  .legend {
    color: black;
    margin-top: 2em;
  }

  .login {
    margin-top: 24px;
    text-align: center;
  }

  a {
    color: $primary;
  }
}
