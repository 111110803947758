.breadcrumbItem {
  text-transform: uppercase;
  color: #292c33;
  font-size: 0.8rem;
  font-weight: 500;
  letter-spacing: 0.113rem;
  opacity: 0.3;
  &.last {
    opacity: 1;
  }
}
