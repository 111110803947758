@import 'src/styles/variables';

.container {
  width: 100%;
  position: relative;
  padding: 0 7.333rem 3.8rem;
  > *:first-child {
    position: absolute;
    top: -8rem;
    right: 0;
    width: 100%;
    padding: 0 7.333rem;
    & + * {
      margin-top: 29rem;
    }
  }
}
@media only screen and (max-width: $breakpoint-sm) {
  .container {
    padding: 0 1rem 0.5rem;
    margin-top: -5rem;
    > *:first-child {
      position: initial;
      padding: 0;
      flex-direction: column;
      align-items: center;
      & + * {
        margin-top: 2rem;
      }
    }
  }
}
