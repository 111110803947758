.container .firstSection {
  padding: 7% 5% 5%;
  background-color: #FFFFFF; }
  @media only screen and (max-width: 576px) {
    .container .firstSection {
      padding: 20% 5% 5%; } }
  .container .firstSection h1 {
    font-size: 24px;
    margin-bottom: 0; }
  .container .firstSection .communityGallery {
    padding: 3% 0;
    display: flex;
    flex-wrap: wrap;
    gap: 25px; }
    @media only screen and (max-width: 576px) {
      .container .firstSection .communityGallery {
        gap: 15px; } }
    .container .firstSection .communityGallery a {
      width: 32%; }
      @media only screen and (max-width: 576px) {
        .container .firstSection .communityGallery a {
          width: 30%; } }
      .container .firstSection .communityGallery a img {
        width: 100%;
        border-radius: 8px; }

.container .secondSection {
  padding: 5% 5%;
  background-color: #dddddd; }
  .container .secondSection h2 {
    font-size: 24px;
    margin-bottom: 0; }
  .container .secondSection .addsomeGallery {
    padding: 3% 0;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 25px; }
    @media only screen and (max-width: 576px) {
      .container .secondSection .addsomeGallery {
        gap: 10px; } }
    .container .secondSection .addsomeGallery a {
      width: 48%; }
      .container .secondSection .addsomeGallery a img {
        width: 100%;
        border-radius: 8px; }

.container .thirdSection {
  padding: 5% 5%;
  background-color: #000000; }
  .container .thirdSection h2 {
    font-size: 24px;
    margin-bottom: 0; }
  .container .thirdSection h2, .container .thirdSection span {
    color: #FFFFFF; }
  .container .thirdSection .popularGallery {
    padding: 3% 0;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 25px; }
    .container .thirdSection .popularGallery a {
      width: 48%; }
      .container .thirdSection .popularGallery a img {
        width: 100%; }

.loaderContainer {
  height: 95vh; }

.sceneContainer {
  display: flex;
  justify-content: space-between;
  padding: 10% 5% 0;
  gap: 25px;
  background-color: #FFFFFF; }
  @media only screen and (max-width: 576px) {
    .sceneContainer {
      flex-direction: column;
      padding: 20% 5% 10%; } }
  .sceneContainer .picturesPart {
    height: 500px;
    width: 48%;
    position: relative;
    flex-grow: 1; }
    @media only screen and (max-width: 576px) {
      .sceneContainer .picturesPart {
        width: 100%;
        height: 250px; } }
    .sceneContainer .picturesPart .currentPicture {
      width: 100%;
      border-radius: 8px;
      height: 100%;
      object-fit: cover; }
    .sceneContainer .picturesPart .leftIcon, .sceneContainer .picturesPart .rightIcon {
      width: 10%;
      height: 10%;
      position: absolute;
      bottom: 0;
      top: 0;
      margin: auto 0;
      color: #FFFFFF;
      cursor: pointer; }
      .sceneContainer .picturesPart .leftIcon svg, .sceneContainer .picturesPart .rightIcon svg {
        width: 100%;
        height: 100%; }
    .sceneContainer .picturesPart .leftIcon {
      left: 0; }
    .sceneContainer .picturesPart .rightIcon {
      right: 0; }
  .sceneContainer .productsPart {
    height: 500px;
    width: 48%;
    background-color: #F0F0F0;
    padding: 2%;
    border-radius: 8px;
    flex-grow: 1;
    position: relative;
    padding-bottom: 0;
    overflow-y: auto; }
    @media only screen and (max-width: 576px) {
      .sceneContainer .productsPart {
        width: 100%;
        height: 455px; } }
    .sceneContainer .productsPart h3 {
      margin: 0; }
    .sceneContainer .productsPart .productHeader {
      display: flex;
      justify-content: space-between; }
    .sceneContainer .productsPart .productsWrapper {
      padding: 3% 0;
      padding-bottom: 0;
      display: flex;
      gap: 3%;
      flex-wrap: wrap;
      width: 100%;
      height: 90%; }
      .sceneContainer .productsPart .productsWrapper .iconWrapper i:first-child {
        cursor: pointer;
        position: absolute;
        bottom: 1%;
        left: 40%; }
      .sceneContainer .productsPart .productsWrapper .iconWrapper i:nth-child(2) {
        cursor: pointer;
        position: absolute;
        bottom: 1%;
        right: 40%; }
      .sceneContainer .productsPart .productsWrapper .productCard {
        width: 31%;
        height: 47%; }
        @media only screen and (max-width: 576px) {
          .sceneContainer .productsPart .productsWrapper .productCard {
            height: 40%; } }
        .sceneContainer .productsPart .productsWrapper .productCard .productThumbnail {
          width: 100%;
          height: 60%;
          background-color: #FFFFFF;
          cursor: pointer;
          border-radius: 8px; }
          .sceneContainer .productsPart .productsWrapper .productCard .productThumbnail img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            border-radius: inherit; }
        .sceneContainer .productsPart .productsWrapper .productCard .productDescription h4 {
          margin-bottom: 0;
          margin-top: 0.5em; }
        .sceneContainer .productsPart .productsWrapper .productCard .productDescription div {
          width: 100%;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden; }

.adminTableContainer {
  padding: 5%;
  min-height: 90vh; }
  .adminTableContainer .productsColumn {
    cursor: pointer; }
  .adminTableContainer .thumbnail {
    cursor: pointer; }
    .adminTableContainer .thumbnail img {
      width: 60px;
      object-fit: cover; }

.adminProductsWrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 4%; }
  .adminProductsWrapper .adminProductCard {
    width: 30%;
    color: inherit; }
    .adminProductsWrapper .adminProductCard h3 {
      margin-bottom: 0; }
    .adminProductsWrapper .adminProductCard img {
      width: 100%;
      height: 60%;
      object-fit: scale-down;
      border: 0.5px solid #dddddd;
      border-radius: 8px; }

.adminMediasWrapper {
  display: flex;
  gap: 20px;
  overflow-x: auto;
  height: 50vh; }
  .adminMediasWrapper .adminMediaCard {
    padding-bottom: 10px;
    width: 100%; }
    .adminMediasWrapper .adminMediaCard img {
      height: 100%;
      width: auto;
      border: 1px solid #dddddd;
      border-radius: 8px; }

[class*="ant-modal"] {
  min-width: 60vw; }
