.notFoundContainer {
  height: 90vh;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 100%;
  }
  h1 {
    color: white;
    position: absolute;
  }
  span {
    color: white;
    position: absolute;
    top: 30vh;
    font-size: 8vh;
    font-weight: bold;
  }
}