@import 'src/styles/variables';

.container {
  padding: 0 6rem;
  .titleContainer {
    min-height: 120px;
    .title {
      font-family: 'Inter';
      font-size: 2.66rem;
    }
    .subtitle {
      color: #000000;
      opacity: 0.7;
      font-size: 1.2rem;
    }
  }
  .foldersTitle {
    margin: 2.133rem 0 1.6rem;
  }
  .filesTitle {
    margin-top: 2.133rem;
  }
}
@media only screen and (max-width: $breakpoint-sm) {
  .container {
    padding: 0 1rem;
  }
}
