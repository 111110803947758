.formItem {
  margin-bottom: 0 !important;
  border-collapse: collapse;
}

.actions {
  display: flex;
  justify-content: flex-end;
  > * + * {
    margin-left: 0.5em;
  }
}