@import 'src/styles/variables.scss';

.media {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: $MEDIA_WIDTH;
  position: relative;

  img {
    width: $MEDIA_WIDTH;
    height: $MEDIA_HEIGHT;
    object-fit: cover;
    transition: all 0.2s ease;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.05);
    z-index: 1;
  }

  .controls {
    display: flex;
    justify-content: space-between;
    line-height: 1;
    width: 100%;
    margin-top: 1em;
  } 
   .flex {
    display: flex;
    align-items:center;
   a, div {
    margin-left: 1vw;
    cursor: pointer;
   }
  }

  &.disabled {
    img {
      opacity: 0.4;
    }
    .actions {
      color: black;
    }
  }

  .actions {
    position: absolute;
    right: 1rem;
    top: 1rem;
    z-index: 3;
    font-size: 1.3em;
    color: white;

    display: flex;
    justify-content: flex-end;
    > * + * {
      margin-left: 1rem;
    }

    a {
      color: inherit;
    }
    
    opacity: 0;

    &:focus-within {
      opacity: 1;
    }
  }

  &:hover {
    .actions {
      opacity: 1;
    }
    &:not(.disabled) img {
      filter: brightness(0.6);
      background: rgba(0,0,0,0.4);
    }
  }
}
