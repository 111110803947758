@import "../../../styles/colors";

.container {
  th:last-child,
  td:last-child {
    text-align: center;
  }
  i,
  svg {
    position: relative !important;
  }

  th:last-of-type {
    i,
    svg :last-of-type {
      position: absolute !important;
      margin-right: 30px;
    }
  }

  th {
    padding: 16px 7px !important;
  }
}

.check {
  height: 1.6rem;
  width: 1.6rem;
  background-color: $primary;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  > i {
    color: white;
  }
}

.enableCell {
  position: relative;
  display: flex;
  width: 100px;
}
