@import '../../styles/variables.scss';
.container {
  padding: 8%;
  display: flex;
  justify-content: space-between;
  background: #eeeeee;
  min-height: 90vh;
  @media (max-width: $breakpoint-sm) {
    flex-direction: column;
    align-items: center;
    gap: 25px;
    padding-top: 25%;
    justify-content: flex-start;
  }
  input {
    height: 2rem;
  }
  select {
    width: 100%;
    height: 2rem;
    border-radius: 5px;
    background-color: transparent;
    border: 1px solid #d1d1d1;
  }
  select:focus {
    border: 1px solid #151515;
  }
  select:focus-visible {
    border: 1px solid #151515;
  }

  .leftPartWrapper {
    width: 20%;
    @media (max-width: $breakpoint-sm) {
      width: 95%;
    }
    .leftPartList{
    background-color: white;
    padding: 8%;
      .accountList {
        margin-top: 5%;
        h4:hover{
          cursor: pointer;
          background-color: #ddd;
        }
        i {
          margin-right: 7px;
        }
      }
      .selected {
        background-color: #ddd;
      }
    }
    .leftPartLogout {
      margin-top: 10%;
      background-color: white;
      padding: 8%;
      i {
        margin-right: 7px;
      }
      h4:hover{
        cursor: pointer;
        background-color: #ddd;
      }
    }
  }
  .rightPartWrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 75%;
    @media (max-width: $breakpoint-sm) {
      width: 95%;
    }
    .profileFirstSection {
      background-color: white;
      padding: 2%;
      .profileFirsSubsection {
        display: flex;
        justify-content: space-between;
        @media (max-width: $breakpoint-sm) {
          flex-direction: column;
        }
        .thumbnailImg {
          height: 4rem;
          width: 4rem;
          object-fit: cover;
          border-radius: 50%;
          margin-right: 10px;
          svg {
            height: 100%;
            width: 60%;
          }
        }
        .thumbnailSection {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          @media (max-width: $breakpoint-sm) {
            justify-content: start;
            padding-top: 15px;
          }
          .editLink {
            cursor: pointer;
          }
        }
      }
      input {
        border-radius: 0px;
        border: none;
        border-bottom: 1px solid #d1d1d1;
      }
      select, select:focus, select:focus-visible {
        border-radius: 0px;
        border: none;
        border-bottom: 1px solid #d1d1d1;
      }
      .formWrapper{
        display: flex;
        justify-content: space-between;
        padding: 15px 0;
        @media (max-width: $breakpoint-sm) {
          flex-direction: column;
          align-items: center;
        }
        .formContainer{
          width: 45%;
          @media (max-width: $breakpoint-sm) {
            width: 95%;
          }
          .formGroup {
            display: flex;
            align-items: center;
            .inputError {
              border-bottom: 2px solid red;
            }
            > div:nth-child(1) {
              margin-bottom: 0;
              width: 170px;
              text-align: right;
            }
            [class*=ant-select-selection--single] {
              height: 2rem!important;
            }
          }
          .saveBtn, .changeBtn {
            display: flex;
            justify-content: flex-end;
            padding: 3% 0;
          }
          .changeBtn {
            text-decoration: underline;
            cursor: pointer;
          }
        }
      }
    }
    .profileSecondSection {
      background-color: white;
      padding: 2%;
      .preferences {
        padding: 3% 0;
        select {
          width: 20%;
          @media (max-width: $breakpoint-sm) {
            width: 40%;
          }
        }
        h4 {
          width: 250px;
          margin-bottom: 0;
          @media (max-width: $breakpoint-sm) {
            width: 170px;
          }
        }
        .group {
          display: flex;
          align-items: center;
          padding-bottom: 3%;
          span {
            cursor: pointer;
            text-decoration: underline;
          }
        }
      }
    }
    .subscriptionFirstSection {
      background-color: white;
      padding: 2%;
      .subscriptionDetails {
        padding: 3% 0;
        .group {
          display: flex;
          align-items: center;
          padding-bottom: 3%;
          span {
            cursor: pointer;
          }
          h4 {
            width: 250px;
            margin-bottom: 0;
          }
        }
      }
    }
    .purchaseFirstSection {
      background-color: white;
      padding: 2%;
      .detailsBlock {
        display: flex;
        justify-content: space-between;
        padding: 0.8rem;
        @media (max-width: $breakpoint-sm) {
          padding: 0.8rem 0 ;
        }
        i {
          color:rgba(0, 0, 0, 0.65)
        }
        span {
          font-size: 1rem;
        }
        .boldSpan {
          font-weight: bold;
          font-size: 1.2rem;
          color: black;
        }
        .paymentsBlock {
          height: 9rem;
          overflow: auto;
          width: 100%;
          @media (max-width: $breakpoint-sm) {
            >div {
              width: 200%;
            }
            height: 17rem;
          }
          .paymentReason {
            width: 25%;
            @media (max-width: $breakpoint-sm) {
              width: 60%;
            }
          }
          .paymentStatus {
            width: 15%;
            @media (max-width: $breakpoint-sm) {
              width: 20%;
            }
          }
          .paymentDate {
            width: 20%;
            @media (max-width: $breakpoint-sm) {
              width: 40%;
            }
          }
          .paymentAmount {
            width: 20%;
            @media (max-width: $breakpoint-sm) {
              width: 30%;
            }
          }
          .paymentInvoiceDownload {
            width: 20%;
            @media (max-width: $breakpoint-sm) {
              width: 60%;
            }
            a {
              color: gray;
              i {
                color: black;
              }
            }
          }
        }

        .paymentsBlock:hover {
          overflow-y: auto;
          //padding-right: 0.5rem;
        }

        div {
          display: flex;
          justify-content: space-between;
        }
      }
    }
  }
}
.formPasswordChange {
  [class*=ant-form-item] {
    margin-bottom: 0 !important;
  }

  input, input:focus-visible, input:focus, input:hover {
    height: 2rem;
    border-radius: 0px;
    border: none!important;
    border-bottom: 1px solid #d1d1d1 !important;
  }
}

.changePasswordModal {
  [class*=ant-modal-footer] {
    flex-direction: column-reverse;
    gap: 20px;
    justify-content: center;
    align-items: center;
    button {
      margin: 0;
      height: 2.5rem;
    }
  }
  .wrongPassword {
    color: red;
    margin-top: 20px;
  }
}