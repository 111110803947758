@import '../../styles/variables';

.container {
  margin: 0 6em;
}

.topPanel {
  height: 352px;
  margin: -136px 0 40px;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.03);
  background: white;
}

.topPanel {
  text-align: center;
  color: black;
}

.topPanelNbClients {
  padding: 24px 0 8px;
  font-size: 48px;
  line-height: 56px;
  font-weight: bold;
}

.topPanelNbClientsText {
  font-size: 15px;
  line-height: 24px;
  opacity: 0.6;
}

.topPanelAddedRecently {
  margin: 16px 0 24px;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 1.7px;
}

.topPanelClientList {
  display: flex;
  justify-content: center;
  padding: 0;
  margin: 0;
}

.topPanelClientListItem {
  display: flex;
  flex-direction: column;
  width: 120px;
  margin: 0 24px;
}

.topPanelClientListItemImage {
  height: 120px;
  border-right: 4px;
  cursor: pointer;
  > img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.topPanelClientListItemLabel {
  font-size: 13px;
  line-height: 18px;
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@media (max-width: 1830px) {
  .topPanelClientListItem:nth-child(10) {
    display: none;
  }
}

@media (max-width: 1660px) {
  .topPanelClientListItem:nth-child(9) {
    display: none;
  }
}

@media (max-width: 1480px) {
  .topPanelClientListItem:nth-child(8) {
    display: none;
  }
}

@media (max-width: 1350px) {
  .topPanelClientListItem:nth-child(7) {
    display: none;
  }
}

@media (max-width: 1200px) {
  .topPanelClientListItem:nth-child(6) {
    display: none;
  }
}
@media only screen and (max-width: $breakpoint-sm) {
  .container {
    margin: 0 1rem;
  }
  .topPanelClientList {
    flex-wrap: wrap;
  }
  .topPanel {
    height: 100%;
  }
  .topPanelClientListItemLabel {
    text-align: center;
  }
}
