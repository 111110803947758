@import "src/styles/variables";

.modal {
  .body {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    height: 85vh;
    padding: 1em;
    > * + * {
      margin-left: 1.5rem;
    }
    > *:first-child {
      width: 60%;
    }
    > *:nth-child(2) {
      width: 40%;
      overflow-y: auto;
    }
  }
  .hidden {
    display: none;
  }
  .loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 85vh;
    font-size: 2rem;
  }

  &.isInCatalog {
    .body {
      padding-left: 2em;
    }
  }
}
@media only screen and (max-width: $breakpoint-sm) {
  .modal {
    .body {
      flex-direction: column;
      gap: 1rem;
      height: auto;

      > * + * {
        margin-left: 0;
      }
      > *:first-child {
        width: 100%;
        height: 358px;
      }
      > *:nth-child(2) {
        width: 100%;
        overflow-y: auto;
      }
    }
    [class*="ant-modal-close-x"] {
      line-height: 30px;
      width: 30px;
      height: 30px;
    }

    &.isInCatalog {
      [class*="ant-modal-close"] {
        top: 3px;
      }
      .body {
        padding-left: 1em;
      }
    }
  }
}
