@import 'src/styles/colors';
@import 'src/styles/variables';

.registerInitFormModal {
  width: 100%;
  [class*='ant-form-item'] {
    margin-bottom: 0;
  }
  div[class*='ant-select-selection-selected-value'] {
    line-height: 3.2rem!important;
  }
  .group {
    display: flex;
    justify-content: space-between;
    @media only screen and (max-width: $breakpoint-sm) {
      flex-direction: column;
    }
    > div {
      width: 48%;
      @media only screen and (max-width: $breakpoint-sm) {
        width: 100%;
      }
    }
  }
  .legend {
    color: black;
    margin: 0;
    // margin-top: 2em;
  }
  .signInBtns {
    display: flex;
    justify-content: space-between;
    @media only screen and (max-width: $breakpoint-sm) {
      flex-direction: column;
    }
    button {
      @media only screen and (max-width: $breakpoint-sm) {
        width: 100%!important;
      }
    }
  }

  .submit {
    margin-top: 20px;
  }

  .login {
    margin-top: 10px;
    text-align: center;
  }

  a {
    color: $primary;
  }
}
