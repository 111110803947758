@import '../../styles/colors';

.uploadMedia {
  display: block;
  [class*='ant-upload'][class*='ant-upload-select'] {
    display: block;
  }
}

div.notificationContainer {
  padding: 16px !important;
  div[class*='ant-notification-notice-message'] {
    margin-bottom: 0;
    margin-left: 40px;
  }
  i[class*='anticon anticon-check-circle-o ant-notification-notice-icon ant-notification-notice-icon-success'] {
    color: $primary;
    margin-left: 0;
  }
}
