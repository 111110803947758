.userThumbnail {
  display: flex;
  flex-direction: column;
  width: 93px;
}

.name {
  color: #ffffff;
  font-family: 'Inter';
  font-size: 14px;
  font-weight: 500;
  margin-top: 0.5333rem;
  line-height: 24px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.stats {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  & > div svg {
    path {
      color: #151515;
    }
    margin-right: 0.2667rem;
  }
  & > div > span {
    color: #151515;
    font-family: 'Inter';
    font-size: 14px;
    line-height: 24px;
  }
}
