@import 'src/styles/variables';

.container {
  min-height: 640px;
}

.stepZeroContainer {
  width: 100% !important;
}

.username {
  color: $primary;
}

@media (min-width: 768px) {
  .verticalLine {
    position: relative;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 50%;
      width: 1px;  // Width of the vertical line
      background-color: grey;  // Color of the vertical line
      transform: translateX(-50%);
      z-index: 1;
    }
  }
}


.registerModal {

  height: 80vh;
  display: flex;
  position: relative;
  @media only screen and (max-width: $breakpoint-sm) {
    flex-direction: column;
    height: auto;
  }
  p {
    padding: 0!important;
  }
  .imageContainer {
    width: 109%;
    height: 100%;
    @media only screen and (max-width: $breakpoint-sm) {
      width: 100%;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-bottom-left-radius: 6px;
      border-top-left-radius: 6px;
    }
  }
  .registerModalStep {
    @media only screen and (max-width: $breakpoint-sm) {
      width: 100%;
    }
    height: 100%;
    padding: 3% 4%;
    width:100%;

    h1 {
      margin: 0;
    }
    .registerModalForm {
      height: 95%;
      display: flex;
      align-items: center;
      justify-content: center;
      .scaleForm {
        transform: scale(0.85);
      }
    }
  }
}

.scaleForm {
  transform: scale(0.85);
}

.end {
  margin-top: 24px;
  text-align: center;
  color: #333;
  font-size: 1rem;
}
.registerHeader{
  z-index: 100;
  background: none;
  position: absolute;
  width: 100%;
  padding: 0 $container-padding;
  line-height: 0;
  background-color: #151515;
  top: 0;
  height: 4.5rem;
  .backSection {
    position: absolute;
    top: 40%;
    right: 2%;
    z-index: 1000;
    color: white;
    font-weight: bold;
    cursor: pointer;
  }
}

.radioRegisterStep{

  display: flex;
  justify-content: center;

  .wrapper {
    --font-color-dark: #323232;
    --font-color-light: #FFF;
    --bg-color: #000000;
    --main-color: #FFFFFF;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-around;
    background-color: var(--bg-color);
    border: 2px solid var(--main-color);
    border-radius: 34px;
    display: flex;
    flex-direction: row;
    width: 10vw;
    margin: 0.5%;
    padding: 0.15%;
    position: absolute;
    z-index: 1;
    top:0;
    bottom: 0;

    .input {
      border: none;
    }
  }
  
  .option {
    position: relative;
  }
  
  .input {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    appearance: none;
    margin: 0;
  }

  .input:focus {
    outline: none;
  }
  
  .btn {
    height: 4vh;
    width: 2.5vw;
    background-color: var(--bg-color);
    border-radius: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .span {
    color: var(--main-color);
  }
  
  .input:checked + .btn {
    background-color: var(--main-color);
  }
  
  .input:checked + .btn .span {
    color: var(--bg-color);
  }
}

.radioRegisterStepModal{

  .wrapper {
    --font-color-dark: #323232;
    --font-color-light: #FFF;
    --bg-color: #000000;
    --main-color: #FFFFFF;
    align-items: center;
    justify-content: space-around;
    background-color: var(--main-color);
    border: 2px solid var(--bg-color);
    border-radius: 34px;
    display: flex;
    flex-direction: row;
    width: 7vw;
    margin: 0.5%;
    padding: 0.15%;
    position: absolute;
    z-index: 1;
    top:0;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;

    @media only screen and (max-width: $breakpoint-sm) {
      width: 30vw;
    }

    .input {
      border: none;
      cursor: pointer;
    }
  }
  
  .option {
    position: relative;
  }
  
  .input {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    appearance: none;
    margin: 0;
  }

  .input:focus {
    outline: none;
    border: none;
    box-shadow: none;
  }
  
  .btn {
    height: 3vh;
    width: 1.8vw;
    @media only screen and (max-width: $breakpoint-sm) {
      width: 7vw;
    }
    background-color: var(--main-color);
    border-radius: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .span {
    color: var(--bg-color);
  }
  
  .input:checked + .btn {
    background-color: var(--bg-color);
  }
  
  .input:checked + .btn .span {
    color: var(--main-color);
  }
}
@media only screen and (max-width: $breakpoint-sm) {
  .radioRegisterStep{
    .wrapper {
      width: 30vw;
    }
    .btn {
      width: 3.5vh;
      height: 6.5vw;
    }
  }
}
