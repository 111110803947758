@import '../../styles/colors';

.gallery {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  > * {
    margin-bottom: 2em;
    margin-right: 2.5em;
  }
}

.notificationContainer {
  padding: 16px !important;
  div[class*='ant-notification-notice-message'] {
    margin-bottom: 0;
    margin-left: 40px;
  }
  i[class*='anticon anticon-check-circle-o ant-notification-notice-icon ant-notification-notice-icon-success'] {
    color: $primary;
    margin-left: 0;
  }
}
