$black: #000;
$white: #fff;
$primary: #151515;
$grey: #d9d9d9;

$odd-green: #00343d;

$white-60: transparentize($white, 0.4);
$black-50: transparentize($black, 0.5);

$error: #ff8c64;

$category-yellow: #f2b230;
$category-blue: #3c82ff;
$category-cyan: #70bac2;
$category-dark-blue: #30535a;
$category-light-grey: #d3dfe0;
