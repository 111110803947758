@import 'src/styles/variables';

.header {
  z-index: 2;
  background: none;
  position: absolute;
  width: 100%;
  padding: 0 $container-padding !important;
  line-height: 0 !important;
  background-color: #151515 !important;

  &.darkTheme {
    border-bottom: 1px solid rgba(41, 44, 51, 0.1) !important;
  }

  &.sticky {
    position: sticky;
    top: 0;
  }

  &.white {
    background-color: #fff !important;
  }

  .content {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: inset 0 -1px 0 0 rgba(255, 255, 255, 0.15);
  }

  .logo {
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      height: 40px;
      width: 40px;
      border-radius: 50%;
      object-fit: cover;
    }
  }
}
@media only screen and (max-width: $breakpoint-sm) {
  .header {
    padding: 0.2rem;
    .logo {
      display: none;
    }
  }
}
