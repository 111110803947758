.container {
  display: flex;
  align-items: center;
  height: 48px;
  width: 100%;
  padding: 0 12px 0 16px;
  .label {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-right: 32px;
  }
}
