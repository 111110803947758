@import 'src/styles/colors';
@import 'src/styles/variables';

.smallTitle {
  font-size: 1rem;
  line-height: 1.6rem;
  color: $white;
  font-weight: 500;
  margin-top: 5rem !important;
}

.consultCatalogTitle {
  font-size: 2.6667rem !important;
  line-height: 3.2rem !important;
  color: $white !important;
  margin-bottom: 1.0667rem !important;
}

.container {
  width: 100%;
  position: relative;
  > *:first-child {
    position: absolute;
    top: -6.9333rem;
    right: 0;
    width: 100%;
    padding: 0 7.333rem;
    & + * {
      margin-top: 29rem;
    }
  }

  .metricsContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    > * {
      margin-right: 2rem;
      &:last-child {
        margin-right: 0;
      }
    }
  }

  .contentContainer {
    margin-top: 27.7333rem;

    .section {
      padding: 4.2667rem 7.333rem;

      @media (min-width: $breakpoint-3xl) {
        padding: 4.2667rem 0.4rem;
        max-width: 2070px;
        margin-right: auto;
        margin-left: auto;
      }

      @media (max-width: $breakpoint-3xl) and (min-width: $breakpoint-2xl) {
        padding: 4.2667rem 0.8rem;
        max-width: 1660px;
        margin-right: auto;
        margin-left: auto;
      }

      @media (max-width: $breakpoint-xl) {
        padding: 4.2667rem 3rem;
        margin-right: auto;
        margin-left: auto;
      }

      &.greenSection {
        background-color: #00343d;
        * {
          color: $white !important;
        }
      }

      &.brandSection {
        padding: 0.2667rem 7.333rem;
        padding-bottom: 4.2667rem;
      }

      .sectionTitle {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 1.6rem;

        & h2 {
          font-size: 1.6rem !important;
        }
      }

      .brandGrid {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        justify-content: flex-start;
        grid-gap: 32px;

        .card {
          width: 100%;
          height: 124px;
        }

        @media (min-width: $breakpoint-md) {
          grid-template-columns: repeat(2, 1fr);
        }

        @media (min-width: $breakpoint-lg) {
          grid-template-columns: repeat(auto-fill, 124px);
        }

        @media (min-width: $breakpoint-xl) {
          grid-template-columns: repeat(auto-fill, 124px);
        }

        @media (min-width: $breakpoint-2xl) {
          grid-template-columns: repeat(auto-fill, 124px);
        }
      }
    }
  }
}
@media only screen and (max-width: $breakpoint-sm) {
  .container {
    padding: 0;
    margin-top: -5rem;
    > *:first-child {
      position: initial;
      flex-direction: column;
      padding: 0;
    }
    .contentContainer {
      margin-top: 1rem;
    }
  }
}
