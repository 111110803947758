@import 'src/styles/variables';

.forgotten {
  width: 100%;

  a {
    display: block;
    text-align: center;
    color: $primary;
  }

  > * + * {
    margin-top: 1.5em;
  }
}
