@import 'src/styles/variables';

.pageHeader {
  min-height: 15rem;
  background-size: cover!important;
  background-color: $primary-dark;
  background-position: 50%;

  text-align: center;
  padding-top: 8rem;
  background: black;

  &.dark {
    // background-blend-mode: overlay;
    background-color: rgba(0, 0, 0, 0.4);
  }

  &.big {
    min-height: 60rem;
    margin-top: -18vh;

    h1 {
      margin-top: 12rem;
      font-size: 3.2rem;
      font-family: 'Arkitech Medium' !important;
      text-transform: uppercase;
    }
    span {
      color: white;
    }

    &.hasCards {
      h1 {
        margin-top: 19.5rem;
      }
    }

    .richContent {
      h1 {
        margin-top: 0;
      }

      align-items: flex-end;
    }

    .richLeft {
      height: 10rem;
    }

    .icon {
      height: 10rem;
      width: 10rem;
      background-color: #fff;
    }
  }

  &.megaBig {
    min-height: 75rem;
    margin-top: -11vh;
  }

  &.rich {
    display: flex;
    align-items: flex-end;
  }

  h1 {
    color: white;
    font-weight: bold;
    font-size: 2.2rem;
  }

  .richContent {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    height: 100%;
    width: 100%;
    padding: 0 $container-padding;
    padding-bottom: 2.5em;
  }

  .richLeft {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: white;
    text-align: left;
    height: 6rem;

    >*+* {
      margin-left: 2em;
    }
  }

  .richRight {
    display: flex;
    flex-direction: column;
    align-items: end;
    justify-content: space-between;
  }

  .inputFieldDropdownProductState div {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    width: 250px;
    max-width: 250px;
    margin-left: 10px;
  }

  .info {
    opacity: 0.7;
    font-size: 1rem;

    a {
      color: inherit;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .richTitle {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .icon {
    height: 6rem;
    width: 6rem;
    border-radius: 50%;
    object-fit: cover;
    background: white;
  }

  .enabled {
    color: white;
    margin-top: auto;
  }

  .switchBox {
    display: flex;

    label {
      margin-left: 0.8em;
    }
  }
}

@media only screen and (max-width: $breakpoint-sm) {
  .pageHeader {
    background-position: center center;
    .richTitle {
      justify-content: center;
    }

    .richContent {
      flex-direction: column;
      gap: 2rem;
    }

    .info {
      font-size: 0.8rem;
    }

    &.big {
      min-height: 25rem;
      margin-top: 0vh;
      h1 {
        margin-top: 0;
      }
      .richContent {
        align-items: flex-start;
      }
      &.hasCards {
        h1 {
          margin-top: 5.5rem;
        }
      }

      h1 {
        font-size: 2rem;
      }

      .icon {
        height: 7rem;
        width: 7rem;
      }
    }

    &.megaBig {
      min-height: 30rem;
    }

    h1 {
      font-size: 1.2rem;
    }

    .richRight {
      flex-direction: row;
      align-items: center;
    }
  }
}