@import 'src/styles/variables';

$searchBarContainer-height: 72px;

.container {
  min-height: calc(100vh + #{$searchBarContainer-height});
  background-color: #fff;
}

.filtersContainer {
  position: sticky;
  top: 64px;
  z-index: 1;
  background: #fff;

  &.scrollToTop {
    top: 64px;
  }
  &.scrollToBottom {
    top: 0;
  }
}

.searchBarContainer {
  height: $searchBarContainer-height;
  padding-left: $container-padding;
  padding-right: $container-padding;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid rgba(41, 44, 51, 0.1);
}

.filtersBar {
  padding-left: $container-padding !important;
  padding-right: $container-padding !important;
}

.grid {
  padding-top: 2.1333rem;
  justify-content: center !important;
}
@media only screen and (max-width: $breakpoint-sm) {
  .grid {
    padding: 1rem;
  }
}
