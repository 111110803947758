@import 'src/styles/_colors';
@import 'src/styles/variables';

div.metricCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2.6rem;
  padding-bottom: 2.1333rem;
  width: 25.7rem;
  > * + * {
    margin-top: 1.5rem;
  }

  .container {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    > * + * {
      margin-top: 1.5rem;
    }

    .entityContainer {
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: center;

      > * {
        border-left: 1px solid $grey;
        &:first-child {
          border: none;
        }

        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        align-items: center;
        color: #000000;
        font-size: 32px;
        line-height: 40px;
        font-weight: bold;

        span {
          opacity: 0.6;
          color: #000000;
          font-size: 15px;
          line-height: 24px;
          font-weight: normal;
          letter-spacing: 0;
        }
      }

      .skeleton {
        width: 60px;
        height: 38px;
      }

      & + div {
        @media (max-width: $breakpoint-xl) {
          justify-content: center;
        }
      }
    }

    .thumbnailList {
      height: 13.8rem;
      width: 100%;
    }

    button {
      margin-top: 1.875rem;
    }

    a {
      color: $black;
      font-family: 'Inter';
      margin-top: 0.5rem;
      font-size: 0.9375rem;
      line-height: 2rem;
      text-decoration: underline !important;
    }
  }

  .link {
    margin-top: 2.2rem;
  }
}
@media only screen and (max-width: $breakpoint-sm) {
  div.metricCard {
    width: 23rem;
  }
}
