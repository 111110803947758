.multiStepModal {
  [class*="ant-modal-content"] [class*="ant-modal-body"] {
    padding: 0;
    min-height: 70vh;
  }

  h2 {
    margin-bottom: 1.5em;
  }

  .steps {
    text-align: left;
    font-weight: normal;
  }
}
