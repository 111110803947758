@import url("https://fonts.googleapis.com/css?family=Raleway&display=swap");

.payButton {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  text-align: center;
  .skipLink {
    text-decoration: underline;
    cursor: pointer;
  }
}
.paymentForm {
  padding: 15px;
}
.paymentError {
  margin-top: 10px;
  color: red;
}
.secureBadge {
  img {
    width: 100%;
  }
}
