@import 'src/styles/variables';

img.profilePicture {
  height: 275px;
  width: 385px;
  border-radius: 8px;
  margin-bottom: 8px;
  object-fit: contain;
  background: #fff;
}

h4.name {
  color: #000000;
  font-family: 'Inter';
  font-size: 17px;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 0px;
}

h5.country {
  color: #000000;
  font-family: 'Inter';
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 0px;
}
@media only screen and (max-width: $breakpoint-sm) {
  img.profilePicture {
    width: 100%;
  }
}
