@import 'src/styles/variables.scss';

.metrics {
  display: flex;
  justify-content: flex-end;
  > * + * {
    margin-left: 2em;
  }
}
.brandHeader{
    header {
      min-height: 25rem!important;
      margin-top: 0!important;
  }
}

.container {
  padding: 3em 6em;
}

@media only screen and (max-width: $breakpoint-sm) {
  .container {
    padding: 1rem;
  }
}
