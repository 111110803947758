@import 'src/styles/variables';

.brandInformation {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 100em;
  > *:first-child {
    flex: 0.3;
  }
  > *:last-child {
    flex: 0.6;
  }

  h2 {
    margin-bottom: 1em;
  }
  h3 {
    margin-bottom: 0.5em;
  }

  .images {
    > * + * {
      margin-top: 1.3em;
    }
  }

  .logo {
    display: flex;
    flex-direction: row;
    > * + * {
      margin-left: 2em;
    }

    img {
      height: 9em;
      width: 9em;
      object-fit: contain;
      border: solid 1px #d1d1d1;
      border-radius: 0.3em;
    }
  }

  .description {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  .cover {
    img {
      width: 100%;
      max-height: 25em;
      object-fit: cover;
      border-radius: 0.3em;
    }
    > * + * {
      margin-top: 1.6em;
    }
  }

  .action {
    margin-top: 1rem;
    width: 100%;
  }
}
@media only screen and (max-width: $breakpoint-sm) {
  .brandInformation {
    flex-direction: column;
  }
}
