@import 'src/styles/variables';

.buttonContainer {
  display: flex;
  flex-direction: row;

  & > * {
    margin-right: 8px;
    &:last-child {
      margin-right: 0px;
    }
  }
}

.userGrid {
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  justify-content: flex-start;
  grid-column-gap: 32px;
  grid-row-gap: 26px;
}

@media only screen and (max-width: $breakpoint-sm) {
  .userGrid {
    grid-template-columns: repeat(3, 1fr);
  }
}
