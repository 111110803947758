@import 'src/styles/variables';

.menuItem {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.inputStyle {
  background-color: rgba(41, 44, 51, 0.05);
}

.menu {
  div {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
  }
  ul {
    padding-top: 0px;
    width: 330px;

    overflow-x: hidden;
    li {
      border-radius: 0;
    }
  }
}

.heart {
  font-size: 20px;
  transition: opacity 0.2s ease-in-out;
}

.heart:hover {
  transform: scale(1.2);
}

.list {
  visibility: hidden;
  padding: 0 !important;
  height: 40px !important;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.listItemNotAddedToFav {
  background-color: black;
}

.listItemAddedToFav {
  background-color: grey;
}

@media (max-width: $breakpoint-md) {
  .menu div {
    width: 400px;
  }
}

