.alignTable {
  padding: 5px 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  .ant-form-item-control {
    line-height: 0 !important;
  }
  @media screen and (max-width: 900px) {
    align-items: flex-end;
  }
}
.variationsThumbnail {
  margin-bottom: 1.5%;
  img {
    width: 12%;
  }
  img:hover {
    transform: scale(2.5);
    margin-left: 6em;
    z-index: 100;
    position: relative;
  }
}
.flexStart {
  align-items: flex-start;
  padding: 10px 14px;
  @media screen and (max-width: 900px) {
    align-items: flex-end;
  }
}
.titleFormat {
  font-weight: bold !important;
  margin-block-start: 0 !important;
  margin-block-end: 0 !important;
}
.spaceButtons {
  display: flex;
  gap: 10px;
  align-items: center;
}
.spaceButtonsFlexEnd {
  align-items: flex-end;
}
.cloneInputStyle {
  width: 23vw !important;
  input {
    height: 3.2rem !important;
  }
}
.variationsLine {
  height: 3.2rem;
}
.alignTableVariation {
  display: flex;
  justify-content: flex-start;
  width: 53%;
  gap: 3vw;
  .ant-form-item-control {
    line-height: 0 !important;
  }
  @media screen and (max-width: 900px) {
    flex-direction: column;
    justify-content: center;
    width: 63%;
  }
}

.materialName {
  margin-left: 4vw;
}
.textTable {
  margin-left: 4vw;
}
.ant-form-item-control {
  line-height: 0 !important;
}
.backgroundTable {
  background: darkgray;
  p {
    margin-block-start: 1em;
    margin-block-end: 1em;
  }
}
.flexRight {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  span {
    padding: 0 0.6vw;
  }
}

.flexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ml {
  margin-left: 10px;
}

.flexSpace {
  display: flex;
  justify-content: center;
  align-items: center;
}

.materialSection {
  padding: 0.5vw;
  background: gainsboro;
  @media screen and (min-width: 900px) {
    p {
      margin-block-start: 1em;
      margin-block-end: 1em;
    }
  }
}

.materialEditSection {
  line-height: 0;

  @media screen and (max-width: 900px) {
    flex-direction: column;
    button {
      margin-bottom: 0;
      align-items: flex-start;
    }
  }
}

.materialEditSectionWidth {
  @media screen and (max-width: 900px) {
    flex-direction: column;
    margin-top: 10px;
    width: 100%;
    button {
      margin-bottom: 10px;
      @media screen and (max-width: 900px) {
        margin-bottom: 0;
        justify-content: flex-start;
        align-items: flex-start;
      }
    }
  }
  justify-content: space-around;
  align-items: center;
  width: 85%;
}
.positionForMobile {
  @media screen and (max-width: 900px) {
    width: 333px;
    margin-right: -76px;
  }
}
.inputStyle {
  width: 16vw;
  .ant-form-item-control {
    line-height: unset !important;
  }
  @media screen and (max-width: 900px) {
    width: 100%;
    margin: 2px;
  }
}

.inputStyleAddVariation {
  width: 25vw;
  .ant-form-item-control {
    line-height: unset !important;
  }
  input {
    height: 2.2rem !important;
    width: 60%;
  }
  @media screen and (max-width: 900px) {
    width: 90%;
  }
}
.addInputWidth {
  @media screen and (max-width: 900px) {
    width: 86vw !important;
  }
}
.smallerWidth {
  @media screen and (min-width: 900px) {
    width: 233px;
  }
}
.addVariationInputStyle {
  margin: 0vw;
  width: 70vw;
  @media screen and (max-width: 900px) {
    width: 100%;
  }
}

.fullWidth {
  width: 100%;
}
.flexColumn {
  display: flex;
  flex-direction: column;
}

.rightSpace {
  margin-right: 1vw;
}
.smallerWidth {
  input {
    width: 12.6vw;
    @media screen and (max-width: 900px) {
      width: 165px;
    }
  }
}
.rightSpaceBetweenButtons {
}

.addVariationButton {
  align-self: flex-start;
  justify-self: flex-start;
}

.spaceAddButton {
  margin: 0.6vw;
  @media screen and (max-width: 900px) {
    margin-top: 4vw;
  }
}

.addMaterialButton {
  margin-right: 10px;
}

.colorInputWidth {
  width: 23vw;
  @media screen and (max-width: 900px) {
    width: 100%;
  }
}
.presetInputSelectWidth {
  width: 31vw;
  @media screen and (max-width: 900px) {
    width: 100%;
  }
}

.addVariation {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  @media screen and (max-width: 900px) {
    width: 100%;
    justify-content: space-between;
  }
}

.addVariationNew {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: flex-start;
  @media screen and (max-width: 900px) {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 330%;
  }
}

.tagListContainer {
  display: flex;
  flex-wrap: wrap;
  width: 25vw;
  @media screen and (max-width: 900px) {
    width: 100%;
  }
}

.liStyle {
  margin-top: 1vh;
}

.pointerCursor {
  cursor: pointer;
}

.hrStyle {
  border: 1px solid black;
}
.wrapperLabels {
  display: flex;
  margin: 1vw 0;
  margin-left: 44px;
  flex-wrap: wrap;
  @media screen and (max-width: 900px) {
    margin-left: unset;
  }
}

.labelStyle {
  background-color: #151515;
  border-radius: 50px;
  padding: 0 0.4vw;
  margin: 0.3vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  p {
    margin-bottom: 3px;
    padding: 0 0.2vw;
    color: white;
  }
  @media screen and (max-width: 900px) {
    padding: 0 10px;
    p {
      padding: 0 10px;
    }
  }
}
.alignMaterialsInputAndText {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 1vw 0;
  h2 {
    font-weight: bold;
    margin: 0;
    margin-left: 1vw;
    margin-right: 50px;
    p {
      margin-bottom: 0;
    }
  }
  @media screen and (max-width: 900px) {
    width: 100%;

    justify-content: space-between;
    gap: 10px;
    h2 {
      margin-right: unset;
    }
  }
}

.rightSpaceText {
  margin-right: 0.5vw;
}
.buttons {
  @media screen and (max-width: 900px) {
    margin-right: 44vw;
  }
}

div.container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 15px;

  .colorButton {
    width: 35px;
    height: 35px;
    margin: 0 9px 24px;
  }
}

.adjust {
  padding-top: 0;
}

.userPopup {
  position: absolute;
  width: 25vw;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 8px 24px 0 rgba(0, 0, 0, 0.15);
  top: 45px;
  right: 0px;
  opacity: 0;
  display: flex;
  flex-direction: column;
}

.loader {
  border: 2px solid #f3f3f3;
  border-radius: 50%;
  border-top: 2px solid #3498db;
  width: 20px;
  height: 20px;
  -webkit-animation: spin 1s linear infinite; /* Safari */
  animation: spin 1s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
