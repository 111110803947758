@import 'src/styles/variables';
.container {
  display: flex;
  align-items: center;
  background-color: #FFFFFF;

  @media only screen and (max-width: $breakpoint-sm) {
    flex-direction: column;
  }

  .content {
    flex: 1;
    padding: 5%; /* Change the padding to a percentage value */
    background-color: #FFFFFF;

    h2 {
      font-size: 24px;
    }
    .uploadButton {
      margin-top: 2%; /* Change the margin to a percentage value */
    }
  }

  .imageContainer {
    flex: 1;
    display: flex;
    justify-content: center;
    padding: 5%; /* Change the padding to a percentage value */

    img {
      max-width: 100%;
      height: auto;
      border-radius: 8px;
    }
  }
}
