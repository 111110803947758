@import 'src/styles/colors';
@import 'src/styles/variables';

.consultCatalogSection {
  background-image: url('../../assets/images/bg-catalog-section.png');
  background-position: center;
  background-attachment: fixed;
  height: 34.6667rem;
  // margin-bottom: -4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .smallTitle {
    font-size: 1rem;
    line-height: 1.6rem;
    color: $white;
    font-weight: 500;
  }

  .consultCatalogTitle {
    font-size: 2.6667rem !important;
    line-height: 3.2rem !important;
    color: $white !important;
    margin-bottom: 1.0667rem !important;
  }
}
@media only screen and (max-width: $breakpoint-sm) {
  .consultCatalogSection {
    .consultCatalogTitle {
      text-align: center;
    }
  }
}
